import * as React from "react";
import "./App.css";
import { Component } from "react";
import { variables } from "./Variables.js";

export class Spotlight extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isAdmin !== this.props.isAdmin ||
      prevProps.wallet !== this.props.wallet
    ) {
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      spotlight: [],
      spotlightId: 0,
      spotlightName: "",
      spotlightDescription: "",
      spotlightButtonText: "",
      spotlightButtonLink: "",
      spotlightPhotoFileName: "",
      password: "",
      timeoutId: null,
      errorAlertMessage: null,
    };
  }

  async fetchSpotlight() {
    try {
      const response = await fetch(variables.API_URL + "spotlight");
      if (!response.ok) {
        throw new Error("Failed to fetch spotlight data");
      }
      const jsonData = await response.json();
      if (jsonData.value.length > 0) {
        await this.setState(
          { spotlight: JSON.parse(jsonData.value) },
          () => {}
        );
      } else {
        console.error("No data received");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  componentDidMount() {
    this.fetchSpotlight();
  }

  ErrorAlert() {
    return (
      <div className="container">
        {this.state.errorAlertMessage && (
          <div className="error">{this.state.errorAlertMessage}</div>
        )}
      </div>
    );
  }
  handleError(errorMessage) {
    if (errorMessage) {
      this.setState({ errorAlertMessage: errorMessage });
      if (this.state.timeoutId !== null) {
        clearTimeout(this.state.timeoutId);
      }
      const newTimeoutId = window.setTimeout(() => {
        this.setState({ errorAlertMessage: null });
      }, 5500);
      this.setState({ timeoutId: newTimeoutId });
    }
  }

  changeSpotlightName = (e) => {
    this.setState({ spotlightName: e.target.value });
  };

  changeSpotlightDescription = (e) => {
    this.setState({ spotlightDescription: e.target.value });
  };

  changeSpotlightButtonText = (e) => {
    this.setState({ spotlightButtonText: e.target.value });
  };

  changeSpotlightButtonLink = (e) => {
    this.setState({ spotlightButtonLink: e.target.value });
  };

  changeSpotlightPhotoFileName = (e) => {
    this.setState({ spotlightPhotoFileName: e.target.value });
  };

  changePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  editClick(spotlight) {
    this.setState({
      spotlightName: spotlight.spotlightName,
      spotlightDescription: spotlight.spotlightDescription,
      spotlightButtonText: spotlight.spotlightButtonText,
      spotlightButtonLink: spotlight.spotlightButtonLink,
      spotlightPhotoFileName: spotlight.spotlightPhotoFileName,
    });
  }

  handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === "string") {
          this.setState({ spotlightPhotoFileName: result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  imageUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);

    fetch(variables.API_URL + "spotlight/savefile", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ spotlightPhotoFileName: data.value });
      });
  };

  postPutRequest(requestMethod, spotlight) {
    const data = {
      spotlight: {
        SpotlightId: spotlight.spotlightId,
        SpotlightName: spotlight.spotlightName,
        SpotlightDescription: spotlight.spotlightDescription,
        SpotlightButtonText: spotlight.spotlightButtonText,
        SpotlightButtonLink: spotlight.spotlightButtonLink,
        SpotlightPhotoFileName: spotlight.spotlightPhotoFileName,
      },
      loginModel: {
        Wallet: this.props.wallet == null ? "injwallet" : this.props.wallet,
        Password: spotlight.password,
      },
    };

    fetch(variables.API_URL + "spotlight", {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          this.handleError("Spotlight data submitted successfully  !");
        } else {
          response.text().then((errorMessage) => {
            this.handleError(
              "Failed to submit spotlight data: " + errorMessage
            );
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.handleError("An error occurred while submitting spotlight data.");
      });
  }

  resetState() {
    this.setState({
      spotlightId: 0,
      spotlightName: "",
      spotlightDescription: "",
      spotlightButtonText: "",
      spotlightButtonLink: "",
      spotlightPhotoFileName: "",
      password: "",
      timeoutId: null,
      errorAlertMessage: null,
    });
  }

  createClick(spotlight) {
    if (
      spotlight &&
      spotlight.spotlightName &&
      spotlight.spotlightDescription &&
      spotlight.spotlightButtonText &&
      spotlight.spotlightButtonLink
    ) {
      this.postPutRequest("POST", spotlight);
      this.resetState();
    } else this.handleError("Spotlight parametrs cannot be null!");
  }
  updateClick(spotlight) {
    this.postPutRequest("PUT", spotlight);
    this.resetState();
  }

  getUpdateButton() {
    return (
      <button
        type="button"
        className="px-4 py-2 text-sm border-transparent transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none w-full sm:w-auto bg-[#C62222] text-[#FAFAFA] hover:bg-[#E35151]"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        onClick={() => {
          document
            .getElementsByClassName("container-spotlight")[0]
            .classList.toggle("showmenu");

          this.resetState();
        }}
      >
        <span className="inline-flex items-center gap-1">Update</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-pencil-square"
          viewBox="0 0 16 16"
        >
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
          <path
            fillRule="evenodd"
            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
          />
        </svg>
      </button>
    );
  }
  getUpdateMenu(item) {
    return (
      <div
        className="container-spotlight display-none"
        style={{
          maxWidth: "2200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "rgb(30, 40, 60)",
        }}
      >
        <div className="left-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Name:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.SpotlightName}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeSpotlightName(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Description:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.SpotlightDescription}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeSpotlightDescription(event)}
            />
          </div>
        </div>
        <div className="right-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Link:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.SpotlightButtonLink}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeSpotlightButtonLink(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Text:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.SpotlightButtonText}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeSpotlightButtonText(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.spotlightPhotoFileName !== "" &&
            this.state.spotlightPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.spotlightPhotoFileName}
              />
            ) : item.SpotlightPhotoFileName !== "" &&
              item.EventPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + item.EventPhotoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.imageUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc" }}>Password:</p>
            <input
              type="password"
              id="inputText"
              placeholder="  enter password"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changePassword(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <button
              type="button"
              className="click"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                const spotlight = {
                  spotlightId: item.SpotlightId,
                  spotlightName:
                    this.state.spotlightName === ""
                      ? item.SpotlightName
                      : this.state.spotlightName,
                  spotlightDescription:
                    this.state.spotlightDescription === ""
                      ? item.SpotlightDescription
                      : this.state.spotlightDescription,
                  spotlightButtonLink:
                    this.state.spotlightButtonLink === ""
                      ? item.SpotlightButtonLink
                      : this.state.spotlightButtonLink,
                  spotlightButtonText:
                    this.state.spotlightButtonText === ""
                      ? item.SpotlightButtonText
                      : this.state.spotlightButtonText,
                  spotlightPhotoFileName:
                    this.state.spotlightPhotoFileName != undefined &&
                    this.state.spotlightPhotoFileName != "" &&
                    this.state.spotlightPhotoFileName != null
                      ? this.state.spotlightPhotoFileName
                      : item.SpotlightPhotoFileName,
                  password: this.state.password,
                  wallet:
                    this.props.wallet == null ? "injwallet" : this.props.wallet,
                };
                this.updateClick(spotlight);
              }}
            >
              Update
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.state.spotlight.slice(0, 1).map((spotlight) => (
      <div>
        <div className="absolute">{this.ErrorAlert()}</div>
        <div className="relative h-[580px] w-full flex flex-col">
          <section className="w-full lg:w-[calc(100vw-var(--sidebar-width)-20px)] mx-auto px-51 md:px-16 max-w-[1800px]">
            <section className="absolute z-30 h-full flex items-center justify-between py-20">
              <div>
                <div className="text-accent font-display text-base font-bold mb-2 adaptive-news">
                  {spotlight.SpotlightName}
                </div>
                <div className="font-bold font-display text-3xl max-w-sm mb-6 adaptive-news adaptive-descr">
                  {spotlight.SpotlightDescription}
                </div>
                <div className="w-full mt-6 flex items-stretch sm:flex-row sm:items-end gap-2.5">
                  <a
                    className="!no-underline adaptive-news"
                    target="_blank"
                    href={spotlight.SpotlightButtonLink}
                  >
                    <button
                      type="button"
                      className="px-4 py-2 text-sm border-transparent transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none w-full sm:w-auto bg-[#C62222] text-[#FAFAFA] hover:bg-[#E35151]"
                    >
                      <span className="inline-flex items-center gap-1">
                        {spotlight.SpotlightButtonText}
                      </span>
                    </button>
                  </a>
                  {this.props.isAdmin ? this.getUpdateButton() : null}
                </div>
              </div>
            </section>
          </section>
          <div className="absolute inset-0">
            <img
              className="img-grad h-[480px] object-cover min-h-full min-w-full"
              src={variables.PHOTO_URL + spotlight.SpotlightPhotoFileName}
            ></img>
          </div>
          <div
            className="absolute inset-0"
            style={{
              background:
                "linear-gradient(90deg, rgb(16, 24, 39) 0%, rgba(16, 24, 39, 0.75) 27%, rgba(16, 24, 39, 0) 51%, rgba(16, 24, 39, 0) 100%)",
            }}
          ></div>
        </div>
        {this.props.isAdmin ? (
          <div className="centered">{this.getUpdateMenu(spotlight)}</div>
        ) : null}
      </div>
    ));
  }
}
