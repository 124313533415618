import { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import { variables } from "./Variables";

async function getInjPrice() {
  try {
    const response = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=injective-protocol&vs_currencies=usd"
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data["injective-protocol"].usd;
  } catch (error) {
    console.error("Error fetching INJ price:", error);
    return null;
  }
}

async function fetchTransactionsByAddress(address, limit = 15) { 
  try {
    const response = await fetch(variables.API_URL + `transaction/${address}?limit=${limit}`);
    
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const jsonData = await response.json();

    if (jsonData.length > 0) {
      return jsonData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
}

function formatTransactionDetails(transaction) {
  if (!transaction) {
    return null;
  }

  const txHash = transaction.txhash;
  const txType = transaction.tx.body.messages[0]['@type'] || "none";
  const date = new Date(transaction.timestamp).toISOString().split('T')[0];

  return {
    hash: txHash,
    txtype: txType,
    date: date,
  };
}

async function getLast15TransactionsByAddress(address) {
  if (address === undefined) {
    return null;
  }
  const transactions = await fetchTransactionsByAddress(address);
  console.log(transactions);

  if (!Array.isArray(transactions)) {
    return [];
  }
  if (transactions.length === 0) {
    return [];
  }

  const formattedTransactions = transactions
    .map(formatTransactionDetails)
    .filter(tx => tx !== null);

  return formattedTransactions;
}

function Profile(props) {
  let wallet = props.wallet != undefined ? props.wallet : "";
  const [section, setSection] = useState(true);
  const [arrStats, setArrStats] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const transactions = await getLast15TransactionsByAddress(wallet);
      setArrStats(transactions);
    };

    if (wallet) {
      fetchData();
    }
  }, [wallet]);

  console.log('arr' + arrStats);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(wallet);
  };

  return (
    <>
      <div className="profile-info-bar">
        <img className="user-photo" src="./assets/image112.png"></img>
        <div className="user-info-container">
          <p className="username-profile">[{wallet}]</p>
          <p className="wallet-address">
            [{wallet.substring(0, 5)}...{wallet.slice(-5)}]{" "}
            <button
              className="copy-wallet"
              onClick={() => copyToClipboard(wallet)}
            >
              {wallet ? (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 3V0H8.5C7.67156 0 7 0.671562 7 1.5V10.5C7 11.3284 7.67156 12 8.5 12H14.5C15.3284 12 16 11.3284 16 10.5V4H13.0281C12.45 4 12 3.55 12 3ZM13 0V3H16L13 0ZM6 11V4H1.5C0.671562 4 0 4.67156 0 5.5V14.5C0 15.3284 0.671562 16 1.5 16H7.5C8.32844 16 9 15.3284 9 14.5V13H8C6.89687 13 6 12.1031 6 11Z"
                    fill="#B7BABE"
                  />
                </svg>
              ) : null}
            </button>
          </p>
        </div>
      </div>
      <div className="bg-profile"></div>
      <div className="sections">
        <button
          onClick={() => {
            setSection(true);
          }}
        >
          Profile
        </button>
        <button
          onClick={() => {
            setSection(false);
          }}
        >
          Activity
        </button>
      </div>
      <div className="section-container">
        {section ? (
          <>
            <div className="profile-info">Information will be soon..</div>
          </>
        ) : (
          <>
            {" "}
            <div className="activity-info">
              <div className="about-activity">
                <p>[Transaction Hash]</p>
                <p>[TX TYPE]</p>
                <p>[DATE/TIME]</p>
              </div>
              {arrStats ? (arrStats.map((e) => {
                return (
                  <div className="about-activity">
                    <p>{e.hash}</p>
                    <p>{e.txtype}</p>
                    <p>{e.date}</p>
                  </div>
                );
              })) : null}
            </div>
          </>
        )}
      </div>
      <footer
                  className="mt-auto"
                  aria-labelledby="footer-heading"
                  style={{paddingLeft: '80px'}}
                >
                  <section className="w-full lg:w-[calc(100vw-var(--sidebar-width)-20px)] mx-auto px-51 md:px-16 max-w-[1800px] py-20">
                    <div className="flex items-start flex-col lg:flex-row gap-11">
                      <div className="flex-1">
                        <svg
                          width="252"
                          height="78"
                          viewBox="0 0 252 78"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M68 45.5875H75.1222C75.1222 48.3625 77.4962 49.6375 86.5177 49.6375C93.9959 49.6375 96.2513 48.85 96.2513 46.7875C96.2513 44.6125 94.6686 44.2375 85.6867 43.825C73.6186 43.3 68.8309 41.5 68.8309 35.6125C68.8309 29.8375 75.043 28 85.4098 28C95.8556 28 102.186 30.475 102.186 36.9625H95.0643C95.0643 34.075 92.0176 33.3625 84.6184 33.3625C77.6149 33.3625 75.9531 34 75.9531 35.95C75.9531 37.975 77.6149 38.35 85.6867 38.8C96.6074 39.3625 103.373 39.925 103.373 46.5625C103.373 53.575 96.37 55 86.122 55C74.7661 55 68 53.1625 68 45.5875Z"
                            fill="white"
                          />
                          <path
                            d="M127.716 28.375C134.007 28.375 138.32 32.1625 138.32 38.0125C138.32 43.8625 134.007 47.6125 127.716 47.6125H113.076V54.625H105.954V28.375H127.716ZM125.738 41.95C127.874 41.95 131.198 41.95 131.198 38.0125C131.198 34.0375 127.874 34.0375 125.738 34.0375H113.076V41.95H125.738Z"
                            fill="white"
                          />
                          <path
                            d="M177.411 54.625L174.443 49.0375L159.568 55L145.225 49.0375L142.257 54.625H134.423L148.707 28.375H170.961L185.285 54.625H177.411ZM147.876 44.0125L159.568 49.975L171.792 44.0125L167.812 33.2708L159.568 36.9625L151.325 33.2708L147.876 44.0125Z"
                            fill="white"
                          />
                          <path
                            d="M183.423 41.4625C183.423 32.7625 188.646 28 200.833 28C211.872 28 217.411 31.975 218.124 39.55H210.883C210.21 35.3125 206.768 33.6625 200.833 33.6625C193.592 33.6625 190.545 36.1 190.545 41.4625C190.545 46.8625 193.631 49.3375 200.833 49.3375C206.728 49.3375 210.21 47.65 210.883 43.375H218.124C217.411 50.9875 211.872 55 200.833 55C188.646 55 183.423 50.2 183.423 41.4625Z"
                            fill="white"
                          />
                          <path
                            d="M250 33.7375H227.842V39.0625H250V43.9375H227.842V49.2625H250V54.625H220.72V28.375H250V33.7375Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M37.9078 32.7631L48.2125 36.3699C48.6717 36.5503 49 37.0011 49 37.5196C49 38.0381 48.6939 38.5115 48.2125 38.6693L37.9078 42.2761C34.1229 43.6061 31.3007 46.8975 30.5132 50.9328L26.1594 73.0697C26.0503 73.6335 25.5689 74.039 25 74.039C24.4311 74.039 23.9497 73.6335 23.8403 73.0697L19.4868 50.9328C18.699 46.8975 15.8768 43.6061 12.0922 42.2761L1.78747 38.6693C1.32826 38.4889 1 38.0381 1 37.5196C1 37.0011 1.30614 36.5276 1.78747 36.3699L12.0922 32.7631C15.8768 31.433 18.699 28.1417 19.4868 24.1064L23.8403 1.96934C23.9497 1.40576 24.4311 1 25 1C25.5689 1 26.0503 1.40576 26.1594 1.96934L30.5132 24.1064C31.3007 28.1417 34.1229 31.433 37.9078 32.7631ZM32.5296 34.8199L38.5407 36.867C38.8086 36.9694 39.0001 37.2253 39.0001 37.5195C39.0001 37.8138 38.8215 38.0825 38.5407 38.1721L32.5296 40.2192C30.3218 40.9741 28.6755 42.8421 28.2161 45.1324L25.6764 57.6966C25.6127 58.0166 25.332 58.2468 25.0001 58.2468C24.6682 58.2468 24.3874 58.0166 24.3236 57.6966L21.7841 45.1324C21.3245 42.8421 19.6782 40.9741 17.4705 40.2192L11.4595 38.1721C11.1916 38.0697 11.0001 37.8138 11.0001 37.5195C11.0001 37.2253 11.1787 36.9565 11.4595 36.867L17.4705 34.8199C19.6782 34.065 21.3245 32.1969 21.7841 29.9067L24.3236 17.3424C24.3874 17.0225 24.6682 16.7922 25.0001 16.7922C25.332 16.7922 25.6127 17.0225 25.6764 17.3424L28.2161 29.9067C28.6755 32.1969 30.3218 34.065 32.5296 34.8199Z"
                            fill="white"
                          />
                        </svg>

                        <p className="mt-6 max-w-[450px] font-display text-muted-light">
                          Create, compete and earn rewards on Space - our
                          community website.
                        </p>
                      </div>
                    </div>
                    <div className="mt-11 flex justify-end">
                      <button
                        onClick={() =>
                          window.scrollBy({
                            top: -999999,
                            left: 0,
                            behavior: "smooth",
                          })
                        }
                        type="button"
                        className="px-4 py-2 text-sm text-default bg-muted/20 hover:bg-muted/30 border-transparent backdrop-blur transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none"
                      >
                        Back to top
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                          className="ml-2 w-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </section>
                </footer>
    </>
  );
}
export default Profile;
