import * as React from "react";
import "./App.css";
import { Component } from "react";
import { variables } from "./Variables.js";

export class Event extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isAdmin !== this.props.isAdmin ||
      prevProps.wallet !== this.props.wallet
    ) {
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      modalTitle: "",
      eventId: 0,
      eventName: "",
      eventDescription: "",
      eventTime: "",
      eventHasDeadline: false,
      eventStatus: "",
      eventPhotoFileName: "anonymous.jpg",
      photoPath: variables.PHOTO_URL,
      eventDeadline: "",
      password: "",
      eventRewards: "",
      eventCommunity: "",
      eventsArchived: [],
      eventsNotArchived: [],
      eventIsArchived: false,
      showArchived: false,
      errorAlertMessage: null,
      timeoutId: null,
      template: null,
    };
  }

  async fetchEvents() {
    try {
      const response = await fetch(variables.API_URL + "event");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      if (jsonData.value.length > 0) {
        await this.setState({ events: JSON.parse(jsonData.value) }, () => {});
        await this.setState({
          eventsArchived: this.state.events.filter(
            (item) => item.EventIsArchived === true
          ),
          eventsNotArchived: this.state.events.filter(
            (item) => item.EventIsArchived === false
          ),
        });
      } else {
        console.error("No data received");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    this.checkArchived();
  }

  componentDidMount() {
    this.fetchEvents();
  }

  ErrorAlert() {
    return (
      <div className="container">
        {this.state.errorAlertMessage && (
          <div className="error">{this.state.errorAlertMessage}</div>
        )}
      </div>
    );
  }
  handleError(errorMessage) {
    if (errorMessage) {
      this.setState({ errorAlertMessage: errorMessage });
      if (this.state.timeoutId !== null) {
        clearTimeout(this.state.timeoutId);
      }
      const newTimeoutId = window.setTimeout(() => {
        this.setState({ errorAlertMessage: null });
      }, 5500);
      this.setState({ timeoutId: newTimeoutId });
    }
  }

  changeEventName = (e) => {
    this.setState({ eventName: e.target.value });
  };

  changeEventDescription = (e) => {
    this.setState({ eventDescription: e.target.value });
  };

  changeEventTime = (e) => {
    this.setState({ eventTime: e.target.value });
  };

  changeEventHasDeadline = (e) => {
    this.setState({ eventHasDeadline: e.target.checked });
  };

  changeEventDeadline = (e) => {
    this.setState({ eventDeadline: e.target.value });
  };

  changeEventStatus = (e) => {
    this.setState({ eventStatus: e.target.value });
  };

  changeEventPhotoFileName = (e) => {
    this.setState({ eventPhotoFileName: e.target.value });
  };

  changeEventRewards = (e) => {
    this.setState({ eventRewards: e.target.value });
  };

  changeEventCommunity = (e) => {
    this.setState({ eventCommunity: e.target.value });
  };

  changePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  changeEventIsArchived = (e) => {
    this.setState({ eventIsArchived: e.target.checked });
  };

  editClick(event) {
    this.setState({
      modalTitle: "Edit Event",
      eventName: event.eventName,
      eventDescription: event.eventDescription,
      eventTime: event.eventTime,
      eventHasDeadline: event.eventHasDeadline,
      eventStatus: event.eventStatus,
      eventPhotoFileName: event.eventPhotoFileName,
      eventIsArchived: event.eventIsArchived,
      eventRewards: event.eventRewards,
      eventCommunity: event.eventCommunity,
    });
  }

  handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === "string") {
          this.setState({ eventPhotoFileName: result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  imageUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);

    fetch(variables.API_URL + "event/savefile", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ eventPhotoFileName: data.value });
      });
  };

  postArchive(event) {
    const data = {
      EventId: event.eventId,
    };

    fetch(variables.API_URL + "event/CheckArchived", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
        } else {
          response.text().then((errorMessage) => {
            console.error("Failed to archive event data: " + errorMessage);
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("An error occurred while archiving event data.");
      });
  }

  postStatus(event) {
    const data = {
      EventId: event.eventId,
    };

    fetch(variables.API_URL + "event/CheckStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
        } else {
          response.text().then((errorMessage) => {
            console.error("Failed to change status of event: " + errorMessage);
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("An error occurred while changing status of event.");
      });
  }

  postPutRequest(requestMethod, event) {
    const data = {
      event: {
        EventId: event.eventId,
        EventName: event.eventName,
        EventDescription: event.eventDescription,
        EventTime: event.eventTime,
        EventDeadline:
          event.eventDeadline != undefined ? event.eventDeadline : "01.01.1900",
        EventHasDeadline: event.eventHasDeadline,
        EventStatus: event.eventStatus,
        EventPhotoFileName:
          event.eventPhotoFileName != undefined
            ? event.eventPhotoFileName
            : "anonymous.jpg",
        EventIsArchived: event.eventIsArchived,
        EventRewards: event.eventRewards,
        EventCommunity: event.eventCommunity,
      },
      loginModel: {
        Wallet: this.props.wallet == null ? "injwallet" : this.props.wallet,
        Password: event.password,
      },
    };

    fetch(variables.API_URL + "event", {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          this.handleError("Event data submitted successfully  !");
        } else {
          response.text().then((errorMessage) => {
            this.handleError("Failed to submit event data: " + errorMessage);
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.handleError("An error occurred while submitting event data.");
      });
  }

  resetState() {
    this.setState({
      modalTitle: "",
      eventId: 0,
      eventName: "",
      eventDescription: "",
      eventTime: "",
      eventHasDeadline: false,
      eventStatus: "",
      eventPhotoFileName: "anonymous.jpg",
      photoPath: variables.PHOTO_URL,
      eventDeadline: "",
      eventCommunity: "",
      eventRewards: "",
      eventIsArchived: false,
      password: "",
    });
  }

  checkArchived() {
    this.state.events.forEach((item) => {
      if (
        !item.EventIsArchived &&
        (item.EventStatus === "Finished" ||
          item.EventStatus === "Canceled" ||
          (item.EventHasDeadline && this.checkDate(item.EventDeadline)) ||
          (!item.EventHasDeadline && this.checkDate(item.EventTime)))
      ) {
        item.EventIsArchived = true;
        const event = {
          eventId: item.EventId,
        };
        this.postArchive(event);
      }
    });
  }

  checkOngoing() {
    this.state.events.forEach((item) => {
      if (
        !item.EventIsArchived &&
        item.EventHasDeadline &&
        this.checkDate(item.EventTime) &&
        item.EventStatus === "Upcoming"
      ) {
        item.Status = "Ongoing";
        const event = {
          eventId: item.EventId,
        };
        this.postStatus(event);
      }
    });
  }

  checkDate(targetDateString) {
    return this.daysUntil(targetDateString) < 0;
  }

  daysUntil(targetDateString) {
    const parts = targetDateString.split(" ");
    const datePart = parts[0].split(".");
    const timePart = parts[1].split(":");

    const day = parseInt(datePart[0], 10);
    const month = parseInt(datePart[1], 10);
    const year = parseInt(datePart[2], 10);

    const hours = parseInt(timePart[0], 10);
    const minutes = parseInt(timePart[1], 10);

    const targetDate = new Date(year, month - 1, day, hours, minutes);
    const currentDate = new Date();

    const differenceMs = targetDate.getTime() - currentDate.getTime();
    if (differenceMs <= 0) {
      return -1;
    }
    const days = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
    return days;
  }

  createClick(event) {
    try {
      this.postPutRequest("POST", event);
      this.resetState();
    } catch (errorMessage) {
      if (errorMessage) {
        this.setState({ errorAlertMessage: errorMessage });
        if (this.state.timeoutId !== null) {
          clearTimeout(this.state.timeoutId);
        }
        const newTimeoutId = window.setTimeout(() => {
          this.setState({ errorAlertMessage: null });
        }, 5500);
        this.setState({ timeoutId: newTimeoutId });
      }
    }
  }

  updateClick(event) {
    this.postPutRequest("PUT", event);
    this.resetState();
    //window.location.reload();
  }

  formatDate(inputDate) {
    const date = new Date(this.convertDateFormat(inputDate));
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const hour = date.getUTCHours();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const formattedDate = `${day} ${monthNames[monthIndex]} ${hour} UTC`;

    return formattedDate;
  }

  convertDateFormat(inputDate) {
    const [datePart, timePart] = inputDate.split(" ");
    const [day, month, year] = datePart.split(".");
    const [hour, minute] = timePart.split(":");

    const isoDateString = `${year}-${month}-${day}T${hour}:${minute}:00`;
    return isoDateString;
  }

  getArchivedEvents() {
    const { events } = this.state;

    if (
      events.filter(
        (item) => item.EventIsArchived === true && item.Status != "Canceled"
      ).length == 0
    ) {
      return null;
    }
    return events
      .filter(
        (item) => item.EventIsArchived === true && item.Status != "Canceled"
      )
      .map((item, index) => (
        <a href={item.EventDescription ? item.EventDescription : "/"}>
          <div key={item.EventId}>
            <div className="relative bg-event group w-[250px] h-[366px] rounded-event overflow-hidden cursor-pointer snap-start adaptive-news">
              <div>
                <div className="absolute w-full h-event-img-box object-cover object-center transition-transform duration-500 group-hover:scale-110">
                  <img
                    src={variables.PHOTO_URL + item.EventPhotoFileName}
                    className="img-grayscale"
                    alt={item.EventName != null ? item.EventName : "Event.jpg"}
                  />
                </div>
                <div className="absolute z-10 w-full h-event-text-box event-text-container">
                  <span className="event-text event-text-1">
                    {item.EventTime != null
                      ? this.formatDate(item.EventTime)
                      : "XX.YY.ZZ AA:BB UTC"}
                  </span>
                  <span className="event-text event-text-2">
                    {item.EventName != null ? item.EventName : "Event"}
                    <span className="event-text-2a">
                      {" "}
                      -{" "}
                      {item.EventCommunity != null
                        ? item.EventCommunity
                        : "Injective"}
                    </span>
                  </span>
                  <span className="event-text event-text-3">
                    {item.EventRewards != null
                      ? item.EventRewards.trim()
                      : "Big rewards!"}
                  </span>
                  {this.props.isAdmin ? this.getUpdateButton(index) : null}
                </div>
              </div>
            </div>
            {this.props.isAdmin ? (
              <div className="centered">{this.getUpdateMenu(item)}</div>
            ) : null}
          </div>
        </a>
      ));
  }
  getUpcomingEvents() {
    const { events } = this.state;

    if (events.filter((item) => item.EventStatus == "Upcoming").length == 0) {
      return null;
    }
    return events
      .filter((item) => item.EventStatus == "Upcoming")
      .map((item, index) => (
        <a href={item.eventDescription != null ? item.eventDescription : "/"}>
          <div key={item.EventId}>
            <div className="relative bg-event group w-[250px] h-[366px] rounded-event overflow-hidden cursor-pointer snap-start adaptive-news">
              <div>
                <div className="absolute w-full h-event-img-box object-cover object-center transition-transform duration-500 group-hover:scale-110">
                  <img
                    src={variables.PHOTO_URL + item.EventPhotoFileName}
                    alt={item.EventName != null ? item.EventName : "Event.jpg"}
                  />
                </div>
                <div className="absolute z-10 w-full h-event-text-box event-text-container">
                  <span className="event-text event-text-1">
                    {item.EventTime != null
                      ? this.formatDate(item.EventTime)
                      : "XX.YY.ZZ AA:BB UTC"}
                  </span>
                  <span className="event-text event-text-2">
                    {item.EventName != null ? item.EventName : "Event"}
                    <span className="event-text-2a">
                      {" "}
                      -{" "}
                      {item.EventCommunity != null
                        ? item.EventCommunity
                        : "Injective"}
                    </span>
                  </span>
                  <span className="event-text event-text-3">
                    {item.EventRewards != null
                      ? item.EventRewards.trim()
                      : "Big rewards!"}
                  </span>
                  {this.props.isAdmin ? this.getUpdateButton(index) : null}
                </div>
              </div>
              {this.props.isAdmin ? (
                <div className="centered">{this.getUpdateMenu(item)}</div>
              ) : null}
            </div>
          </div>
        </a>
      ));
  }

  getOngoingEvents() {
    const { events } = this.state;
    if (events.filter((item) => item.EventStatus == "Ongoing").length == 0) {
      return null;
    }
    return events
      .filter((item) => item.EventStatus == "Ongoing")
      .map((item, index) => (
        <a href={item.eventDescription != null ? item.eventDescription : "/"}>
          <div key={item.EventId}>
            <div className="relative bg-event group w-[250px] h-[366px] rounded-event overflow-hidden cursor-pointer snap-start adaptive-news">
              <div>
                <div className="absolute w-full h-event-img-box object-cover object-center transition-transform duration-500 group-hover:scale-110">
                  <img
                    src={variables.PHOTO_URL + item.EventPhotoFileName}
                    alt={item.EventName != null ? item.EventName : "Event.jpg"}
                  />
                </div>
                <div className="absolute z-10 w-full h-event-text-box event-text-container">
                  <span className="event-text event-text-1">
                    {item.EventTime != null
                      ? `Deadline: ${this.formatDate(item.EventTime)}`
                      : "Deadline : XX.YY.ZZ AA:BB UTC"}
                  </span>
                  <span className="event-text event-text-2">
                    {item.EventName != null ? item.EventName : "Event"}
                    <span className="event-text-2a">
                      {" "}
                      {item.EventName.length >= 22
                        ? " "
                        : "- " +
                          (item.EventCommunity != null
                            ? item.EventCommunity
                            : "Injective")}
                    </span>
                  </span>
                  <span className="event-text event-text-3">
                    {item.EventRewards != null
                      ? item.EventRewards.trim()
                      : "Big rewards!"}
                  </span>
                  {this.props.isAdmin ? this.getUpdateButton(index) : null}
                </div>
                w
              </div>
              {this.props.isAdmin ? (
                <div className="centered">{this.getUpdateMenu(item)}</div>
              ) : null}
            </div>
          </div>
        </a>
      ));
  }

  getUpdateButton(index) {
    return (
      <button
        className="update-button click"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        onClick={() => {
          let elements = document.getElementsByClassName("container-events");

          for (let i = 0; i < elements.length; i++) {
            if (index != i && elements[i].classList.contains("showmenu")) {
              elements[i].classList.toggle("showmenu");
            }
          }

          document
            .getElementsByClassName("container-events")
            [index].classList.toggle("showmenu");
          this.resetState();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-pencil-square"
          viewBox="0 0 16 16"
        >
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
          <path
            fillRule="evenodd"
            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
          />
        </svg>
      </button>
    );
  }

  getUpdateMenu(item) {
    return (
      <div
        className="container-events display-none"
        style={{
          maxWidth: "2200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "rgb(30, 40, 60)",
        }}
      >
        <div className="left-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Name:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.EventName}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventName(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Time:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.EventTime}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventTime(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Description Link:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.EventDescription}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventDescription(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc", marginRight: "10px" }}>HasDeadline:</p>
            <input
              type="checkbox"
              id="inputCheckbox"
              defaultChecked={item.EventHasDeadline}
              style={{ display: "none" }}
              onChange={(event) => this.changeEventHasDeadline(event)}
            />
            <label
              htmlFor="inputCheckbox"
              style={{ position: "relative", cursor: "pointer" }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-10px",
                  left: "0",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#ccc",
                  borderRadius: "3px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "none",
                    stroke: "black",
                    strokeWidth: "2",
                    visibility: this.state.eventHasDeadline
                      ? "visible"
                      : "hidden",
                  }}
                >
                  <path d="M20 6L9 17l-5-5" />
                </svg>
              </span>
            </label>
          </div>
          {this.state.eventHasDeadline && (
            <div style={{ marginBottom: "10px" }}>
              <p style={{ color: "#ccc" }}>Deadline:</p>
              <input
                type="text"
                id="inputText"
                defaultValue={
                  item.EventDeadline !== "" &&
                  item.EventDeadline != null &&
                  item.EventDeadline != undefined
                    ? item.EventDeadline
                    : ""
                }
                style={{
                  width: "100%",
                  height: "50px",
                  backgroundColor: "#444",
                  color: "#ccc",
                }}
                onChange={(event) => this.changeEventDeadline(event)}
              />
            </div>
          )}
        </div>
        <div className="right-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Status:</p>
            <select
              name="status"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventStatus(event)}
            >
              <option value="Upcoming">Upcoming</option>
              <option value="Ongoing">Ongoing</option>
              <option value="Finished">Finished</option>
              <option value="Canceled">Canceled</option>
            </select>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Rewards:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.EventRewards}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventRewards(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Community:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.EventCommunity}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventCommunity(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.eventPhotoFileName !== "" &&
            this.state.eventPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.eventPhotoFileName}
              />
            ) : item.EventPhotoFileName !== "" &&
              item.EventPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + item.EventPhotoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.imageUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc" }}>Password:</p>
            <input
              type="password"
              id="inputText"
              placeholder="  enter password"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changePassword(event)}
            />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <button
            type="button"
            className="click"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => {
              const event = {
                eventId: item.EventId,
                eventName:
                  this.state.eventName === ""
                    ? item.EventName
                    : this.state.eventName,
                eventDescription:
                  this.state.eventDescription === ""
                    ? item.EventDescription
                    : this.state.eventDescription,
                eventHasDeadline:
                  this.state.eventDeadline === ""
                    ? false
                    : this.state.eventHasDeadline,
                eventPhotoFileName:
                  this.state.eventPhotoFileName != undefined &&
                  this.state.eventPhotoFileName != "" &&
                  this.state.eventPhotoFileName != null
                    ? this.state.eventPhotoFileName
                    : item.EventPhotoFileName,
                eventTime:
                  this.state.eventTime === ""
                    ? item.EventTime
                    : this.state.eventTime,
                eventStatus:
                  this.state.eventStatus === ""
                    ? item.EventStatus
                    : this.state.eventStatus,
                eventDeadline:
                  this.state.eventDeadline === ""
                    ? item.EventDeadline
                    : this.state.eventDeadline,
                eventIsArchived:
                  this.state.eventStatus === ""
                    ? item.EventStatus === "Finished"
                      ? true
                      : false
                    : this.state.eventStatus === "Finished"
                    ? true
                    : false,
                eventRewards:
                  this.state.eventRewards === ""
                    ? item.EventRewards
                    : this.state.eventRewards,
                eventCommunity:
                  this.state.eventCommunity == ""
                    ? item.EventCommunity
                    : this.state.eventCommunity,
                password: this.state.password,
                wallet:
                  this.props.wallet == null ? "injwallet" : this.props.wallet,
              };
              this.updateClick(event);
            }}
          >
            Update
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }

  createMenu() {
    return (
      <div
        id="createMenu"
        className="container-events display-none"
        style={{
          maxWidth: "2200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "rgb(30, 40, 60)",
        }}
      >
        <div className="left-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Select template:</p>
            <select
              name="status"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
            >
              <option value="SmashKarts">Smash Karts</option>
              <option value="Ongoing">TEMPLATE</option>
            </select>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Name:</p>
            <input
              type="text"
              id="createEventName"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventName(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Time:</p>
            <input
              type="text"
              id="createEventTime"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventTime(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Description Link:</p>
            <input
              type="text"
              id="createEventDescription"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventDescription(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc", marginRight: "10px" }}>HasDeadline:</p>
            <input
              type="checkbox"
              id="createEventHasDeadline"
              style={{ display: "none" }}
              onChange={(event) => this.changeEventHasDeadline(event)}
            />
            <label
              htmlFor="inputCheckbox"
              style={{ position: "relative", cursor: "pointer" }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "-10px",
                  left: "0",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#ccc",
                  borderRadius: "3px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  style={{
                    fill: "none",
                    stroke: "black",
                    strokeWidth: "2",
                    visibility: this.state.eventHasDeadline
                      ? "visible"
                      : "hidden",
                  }}
                >
                  <path d="M20 6L9 17l-5-5" />
                </svg>
              </span>
            </label>
          </div>
          {this.state.eventHasDeadline && (
            <div style={{ marginBottom: "10px" }}>
              <p style={{ color: "#ccc" }}>Deadline:</p>
              <input
                type="text"
                id="createEventDeadline"
                defaultValue=""
                onChange={(event) => this.changeEventDeadline(event)}
              />
            </div>
          )}
        </div>
        <div className="right-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Status:</p>
            <select
              name="status"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventStatus(event)}
            >
              <option value="Upcoming">Upcoming</option>
              <option value="Ongoing">Ongoing</option>
              <option value="Finished">Finished</option>
              <option value="Canceled">Canceled</option>
            </select>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Rewards:</p>
            <input
              type="text"
              id="createEventRewards"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventRewards(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Community:</p>
            <input
              type="text"
              id="createEventCommunity"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeEventCommunity(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.eventPhotoFileName !== "" &&
            this.state.eventPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.eventPhotoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.imageUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc" }}>Password:</p>
            <input
              type="password"
              id="inputText"
              placeholder="  enter password"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changePassword(event)}
            />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <button
            type="button"
            className="click"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => {
              const event = {
                eventId: this.state.eventId,
                eventName: this.state.eventName,
                eventDescription: this.state.eventDescription,
                eventHasDeadline: this.state.eventHasDeadline,
                eventPhotoFileName: this.state.eventPhotoFileName,
                eventTime: this.state.eventTime,
                eventStatus: this.state.eventStatus
                  ? this.state.eventStatus
                  : "Upcoming",
                eventDeadline: this.state.eventDeadline,
                eventIsArchived:
                  this.state.eventStatus &&
                  this.state.eventStatus === "Finished"
                    ? true
                    : false,
                eventRewards: this.state.eventRewards,
                eventCommunity: this.state.eventCommunity,
                password: this.state.password,
                wallet:
                  this.props.wallet == null ? "injwallet" : this.props.wallet,
              };
              this.createClick(event);
            }}
          >
            Create
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }

  getCreateButtonMenu() {
    return (
      <div>
        {this.createMenu()}
        <button
          type="button"
          className="click"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            const doc = document.getElementById("createMenu");
            if (doc) {
              doc.classList.toggle("showmenu");
            }

            this.resetState();
          }}
        >
          Create Event
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fillRule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            />
          </svg>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div
        data-overlay-container="true"
        className="manimate-easy"
        style={{ width: "calc(100vw - 80px)", float: "right" }}
      >
        <div className="h-screen antialiased">
          <div
            className="min-h-[calc(100%-80px)] lg:w-80px w-full relative flex flex-col transition-all duration-700 lg:w-[calc(100%-80px)]"
            style={{ backgroundColor: "#0b111b" }}
          >
            <div className="w-full flex-1 flex flex-col max-w-full ">
              <div className="absolute">{this.ErrorAlert()}</div>
              <main className="grow min-h-[calc(100%-80px)] flex flex-col">
                <div className="flex flex-col">
                  <div className="relative">
                    <div className="relative bg-events-img flex items-center w-full h-full py-8 px-6 sm:py-16 sm:px-16">
                      <span id="box-sizing:border-box;display:block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:absolute;top:0;left:0;bottom:0;right:0">
                        <img
                          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                          decoding="async"
                          data-nimg="fill"
                          id="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover"
                        ></img>
                        <noscript>
                          <img
                            loading="lazy"
                            decoding="async"
                            data-nimg="fill"
                            id="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%;object-fit:cover"
                            sizes="100vw"
                          />
                        </noscript>
                      </span>
                      <div className="relative max-w-lg">
                        <div className="mb-6">
                          <span id="box-sizing:border-box;display:inline-block;overflow:hidden;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;position:relative;max-width:100%">
                            <span id="box-sizing:border-box;display:block;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0;max-width:100%">
                              <img
                                id="display:block;max-width:100%;width:initial;height:initial;background:none;opacity:1;border:0;margin:0;padding:0"
                                alt=""
                                aria-hidden="true"
                                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27156%27%20height=%2746%27/%3e"
                              ></img>
                            </span>
                            <img
                              alt="quest logo"
                              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                              decoding="async"
                              data-nimg="intrinsic"
                              id="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                            ></img>
                            <noscript>
                              <img
                                alt="quest logo"
                                loading="lazy"
                                decoding="async"
                                data-nimg="intrinsic"
                                id="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
                              />
                            </noscript>
                          </span>
                        </div>
                        <h1 className="text-3xl px-5 font-semibold mb-4">
                          Explore Injective Events
                        </h1>
                        <p className="block px-5 text-base text-night-400 font-display mb-3">
                          Earn rewards for play games or complete social and
                          onchain tasks and be rewarded!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-full">
                    <div className="flex items-center">
                      <span className="text-lightblue h-[var(--default-tabs-height)] flex items-center justify-center font-display text-center sm:whitespace-nowrap px-4 md:px-6 z-20 self-end transition-colors">
                        Events
                      </span>
                    </div>
                    <div className="absolute w-full transition-all bottom-0 h-0.5 bg-lightblue"></div>
                    {this.props.isAdmin ? this.getCreateButtonMenu() : null}
                  </div>
                </div>

                <div className="flex flex-col space-y-20 pt-20">
                  {this.getOngoingEvents() !== null ? (
                    <section className="w-full left-0 mx-auto px-51 md:px-16">
                      <div className="flex items-end">
                        <h3 className="font-semibold center-titles">
                          Ongoing Events
                        </h3>
                      </div>
                      <div className="flex flex-col-reverse mt-11 sm:flex-row relative ">
                        <div className="flex grow flex-wrap gap-5 self-start">
                          {this.getOngoingEvents()}
                        </div>
                        <div className="grow hidden md:block"></div>
                      </div>
                    </section>
                  ) : null}
                  {this.getUpcomingEvents() !== null ? (
                    <section className="bg-fix w-full left-0 mx-auto px-51 md:px-16">
                      <div className="flex items-end">
                        <h3 className="font-semibold center-titles">
                          Upcoming Events
                        </h3>
                      </div>
                      <div className="flex flex-col-reverse mt-11 sm:flex-row relative adaptive-news">
                        <div className="flex grow flex-wrap gap-5 self-start">
                          {this.getUpcomingEvents()}
                        </div>
                        <div className="grow hidden md:block"></div>
                      </div>
                    </section>
                  ) : null}
                  {this.getArchivedEvents() !== null ? (
                    <section className="w-full left-0 mx-auto px-51 md:px-16">
                      <div className="flex items-end">
                        <h3 className="font-semibold">Archived Events</h3>
                      </div>
                      <div className="flex flex-col-reverse mt-11 sm:flex-row relative adaptive-news">
                        <div className="flex grow flex-wrap gap-5 self-start">
                          {this.getArchivedEvents()}
                        </div>
                        <div className="grow hidden md:block"></div>
                      </div>
                    </section>
                  ) : null}
                  {this.getArchivedEvents() == null &&
                  this.getUpcomingEvents() == null &&
                  this.getOngoingEvents() == null ? (
                    <div className="relative w-full">
                      <section className="w-full left-0 mx-auto px-51 md:px-16 pb-64">
                        <div className="flex items-end">
                          <h3 className="font-semibold text-3xl px-5 font-semibold mb-4">
                            Stay tuned.
                          </h3>
                        </div>
                      </section>
                      <div className="absolute w-full transition-all bottom-0 h-0.5 bg-lightblue"></div>
                    </div>
                  ) : null}
                </div>
                <footer className="mt-auto" aria-labelledby="footer-heading">
                  <section className="w-full lg:w-[calc(100vw-var(--sidebar-width)-20px)] mx-auto px-51 md:px-16 max-w-[1800px] py-20">
                    <div className="flex items-start flex-col lg:flex-row gap-11">
                      <div className="flex-1 adaptive-news">
                        <svg
                          width="252"
                          height="78"
                          viewBox="0 0 252 78"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M68 45.5875H75.1222C75.1222 48.3625 77.4962 49.6375 86.5177 49.6375C93.9959 49.6375 96.2513 48.85 96.2513 46.7875C96.2513 44.6125 94.6686 44.2375 85.6867 43.825C73.6186 43.3 68.8309 41.5 68.8309 35.6125C68.8309 29.8375 75.043 28 85.4098 28C95.8556 28 102.186 30.475 102.186 36.9625H95.0643C95.0643 34.075 92.0176 33.3625 84.6184 33.3625C77.6149 33.3625 75.9531 34 75.9531 35.95C75.9531 37.975 77.6149 38.35 85.6867 38.8C96.6074 39.3625 103.373 39.925 103.373 46.5625C103.373 53.575 96.37 55 86.122 55C74.7661 55 68 53.1625 68 45.5875Z"
                            fill="white"
                          />
                          <path
                            d="M127.716 28.375C134.007 28.375 138.32 32.1625 138.32 38.0125C138.32 43.8625 134.007 47.6125 127.716 47.6125H113.076V54.625H105.954V28.375H127.716ZM125.738 41.95C127.874 41.95 131.198 41.95 131.198 38.0125C131.198 34.0375 127.874 34.0375 125.738 34.0375H113.076V41.95H125.738Z"
                            fill="white"
                          />
                          <path
                            d="M177.411 54.625L174.443 49.0375L159.568 55L145.225 49.0375L142.257 54.625H134.423L148.707 28.375H170.961L185.285 54.625H177.411ZM147.876 44.0125L159.568 49.975L171.792 44.0125L167.812 33.2708L159.568 36.9625L151.325 33.2708L147.876 44.0125Z"
                            fill="white"
                          />
                          <path
                            d="M183.423 41.4625C183.423 32.7625 188.646 28 200.833 28C211.872 28 217.411 31.975 218.124 39.55H210.883C210.21 35.3125 206.768 33.6625 200.833 33.6625C193.592 33.6625 190.545 36.1 190.545 41.4625C190.545 46.8625 193.631 49.3375 200.833 49.3375C206.728 49.3375 210.21 47.65 210.883 43.375H218.124C217.411 50.9875 211.872 55 200.833 55C188.646 55 183.423 50.2 183.423 41.4625Z"
                            fill="white"
                          />
                          <path
                            d="M250 33.7375H227.842V39.0625H250V43.9375H227.842V49.2625H250V54.625H220.72V28.375H250V33.7375Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M37.9078 32.7631L48.2125 36.3699C48.6717 36.5503 49 37.0011 49 37.5196C49 38.0381 48.6939 38.5115 48.2125 38.6693L37.9078 42.2761C34.1229 43.6061 31.3007 46.8975 30.5132 50.9328L26.1594 73.0697C26.0503 73.6335 25.5689 74.039 25 74.039C24.4311 74.039 23.9497 73.6335 23.8403 73.0697L19.4868 50.9328C18.699 46.8975 15.8768 43.6061 12.0922 42.2761L1.78747 38.6693C1.32826 38.4889 1 38.0381 1 37.5196C1 37.0011 1.30614 36.5276 1.78747 36.3699L12.0922 32.7631C15.8768 31.433 18.699 28.1417 19.4868 24.1064L23.8403 1.96934C23.9497 1.40576 24.4311 1 25 1C25.5689 1 26.0503 1.40576 26.1594 1.96934L30.5132 24.1064C31.3007 28.1417 34.1229 31.433 37.9078 32.7631ZM32.5296 34.8199L38.5407 36.867C38.8086 36.9694 39.0001 37.2253 39.0001 37.5195C39.0001 37.8138 38.8215 38.0825 38.5407 38.1721L32.5296 40.2192C30.3218 40.9741 28.6755 42.8421 28.2161 45.1324L25.6764 57.6966C25.6127 58.0166 25.332 58.2468 25.0001 58.2468C24.6682 58.2468 24.3874 58.0166 24.3236 57.6966L21.7841 45.1324C21.3245 42.8421 19.6782 40.9741 17.4705 40.2192L11.4595 38.1721C11.1916 38.0697 11.0001 37.8138 11.0001 37.5195C11.0001 37.2253 11.1787 36.9565 11.4595 36.867L17.4705 34.8199C19.6782 34.065 21.3245 32.1969 21.7841 29.9067L24.3236 17.3424C24.3874 17.0225 24.6682 16.7922 25.0001 16.7922C25.332 16.7922 25.6127 17.0225 25.6764 17.3424L28.2161 29.9067C28.6755 32.1969 30.3218 34.065 32.5296 34.8199Z"
                            fill="white"
                          />
                        </svg>

                        <p className="mt-6 max-w-[450px] font-display text-muted-light ">
                          Create, compete and earn rewards on Space - our
                          community website.
                        </p>
                      </div>
                    </div>
                    <div className="mt-11 flex justify-end fix-top">
                      <button
                        onClick={() =>
                          window.scrollBy({
                            top: -999999,
                            left: 0,
                            behavior: "smooth",
                          })
                        }
                        type="button"
                        className="px-4 py-2 text-sm text-default bg-muted/20 hover:bg-muted/30 border-transparent backdrop-blur transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none"
                      >
                        Back to top
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                          className="ml-2 w-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </section>
                </footer>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
