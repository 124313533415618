import * as React from "react";
import "./App.css";
import { Component } from "react";
import { variables } from "./Variables";

export class GetEvents extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isAdmin !== this.props.isAdmin ||
      prevProps.wallet !== this.props.wallet
    ) {
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      modalTitle: "",
      eventId: 0,
      eventName: "",
      eventDescription: "",
      eventTime: "",
      eventHasDeadline: false,
      eventStatus: "",
      eventPhotoFileName: "anonymous.jpg",
      photoPath: variables.PHOTO_URL,
      eventDeadline: "",
      password: "",
      eventRewards: "",
      eventCommunity: "",
      eventsArchived: [],
      eventsNotArchived: [],
      eventIsArchived: false,
      showArchived: false,
      errorAlertMessage: null,
      timeoutId: null,
      template: null,
    };
  }

  async fetchEvents() {
    try {
      const response = await fetch(variables.API_URL + "event");
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const jsonData = await response.json();
      if (jsonData.value.length > 0) {
        await this.setState({ events: JSON.parse(jsonData.value) }, () => {});
        await this.setState({
          eventsArchived: this.state.events.filter(
            (item) => item.EventIsArchived === true
          ),
          eventsNotArchived: this.state.events.filter(
            (item) => item.EventIsArchived === false
          ),
        });
      } else {
        console.error("No data received");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    this.checkArchived();
  }

  componentDidMount() {
    this.fetchEvents();
  }

  ErrorAlert() {
    return (
      <div className="container">
        {this.state.errorAlertMessage && (
          <div className="error">{this.state.errorAlertMessage}</div>
        )}
      </div>
    );
  }
  handleError(errorMessage) {
    if (errorMessage) {
      this.setState({ errorAlertMessage: errorMessage });
      if (this.state.timeoutId !== null) {
        clearTimeout(this.state.timeoutId);
      }
      const newTimeoutId = window.setTimeout(() => {
        this.setState({ errorAlertMessage: null });
      }, 5500);
      this.setState({ timeoutId: newTimeoutId });
    }
  }

  changeEventName = (e) => {
    this.setState({ eventName: e.target.value });
  };

  changeEventDescription = (e) => {
    this.setState({ eventDescription: e.target.value });
  };

  changeEventTime = (e) => {
    this.setState({ eventTime: e.target.value });
  };

  changeEventHasDeadline = (e) => {
    this.setState({ eventHasDeadline: e.target.checked });
  };

  changeEventDeadline = (e) => {
    this.setState({ eventDeadline: e.target.value });
  };

  changeEventStatus = (e) => {
    this.setState({ eventStatus: e.target.value });
  };

  changeEventPhotoFileName = (e) => {
    this.setState({ eventPhotoFileName: e.target.value });
  };

  changeEventRewards = (e) => {
    this.setState({ eventRewards: e.target.value });
  };

  changeEventCommunity = (e) => {
    this.setState({ eventCommunity: e.target.value });
  };

  changePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  changeEventIsArchived = (e) => {
    this.setState({ eventIsArchived: e.target.checked });
  };

  editClick(event) {
    this.setState({
      modalTitle: "Edit Event",
      eventName: event.eventName,
      eventDescription: event.eventDescription,
      eventTime: event.eventTime,
      eventHasDeadline: event.eventHasDeadline,
      eventStatus: event.eventStatus,
      eventPhotoFileName: event.eventPhotoFileName,
      eventIsArchived: event.eventIsArchived,
      eventRewards: event.eventRewards,
      eventCommunity: event.eventCommunity,
    });
  }

  handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === "string") {
          this.setState({ eventPhotoFileName: result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  imageUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);

    fetch(variables.API_URL + "event/savefile", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ eventPhotoFileName: data.value });
      });
  };

  postArchive(event) {
    const data = {
      EventId: event.eventId,
    };

    fetch(variables.API_URL + "event/CheckArchived", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
        } else {
          response.text().then((errorMessage) => {
            console.error("Failed to archive event data: " + errorMessage);
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("An error occurred while archiving event data.");
      });
  }

  postStatus(event) {
    const data = {
      EventId: event.eventId,
    };

    fetch(variables.API_URL + "event/CheckStatus", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
        } else {
          response.text().then((errorMessage) => {
            console.error("Failed to change status of event: " + errorMessage);
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        console.error("An error occurred while changing status of event.");
      });
  }

  postPutRequest(requestMethod, event) {
    const data = {
      event: {
        EventId: event.eventId,
        EventName: event.eventName,
        EventDescription: event.eventDescription,
        EventTime: event.eventTime,
        EventDeadline:
          event.eventDeadline != undefined ? event.eventDeadline : "01.01.1900",
        EventHasDeadline: event.eventHasDeadline,
        EventStatus: event.eventStatus,
        EventPhotoFileName:
          event.eventPhotoFileName != undefined
            ? event.eventPhotoFileName
            : "anonymous.jpg",
        EventIsArchived: event.eventIsArchived,
        EventRewards: event.eventRewards,
        EventCommunity: event.eventCommunity,
      },
      loginModel: {
        Wallet: this.props.wallet == null ? "injwallet" : this.props.wallet,
        Password: event.password,
      },
    };

    fetch(variables.API_URL + "event", {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          this.handleError("Event data submitted successfully  !");
        } else {
          response.text().then((errorMessage) => {
            this.handleError("Failed to submit event data: " + errorMessage);
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.handleError("An error occurred while submitting event data.");
      });
  }

  checkArchived() {
    this.state.events.forEach((item) => {
      if (
        !item.EventIsArchived &&
        (item.EventStatus === "Finished" ||
          item.EventStatus === "Canceled" ||
          (item.EventHasDeadline && this.checkDate(item.EventDeadline)) ||
          (!item.EventHasDeadline && this.checkDate(item.EventTime)))
      ) {
        item.EventIsArchived = true;
        const event = {
          eventId: item.EventId,
        };
        this.postArchive(event);
      }
    });
  }

  checkOngoing() {
    this.state.events.forEach((item) => {
      if (
        !item.EventIsArchived &&
        item.EventHasDeadline &&
        this.checkDate(item.EventTime) &&
        item.EventStatus === "Upcoming"
      ) {
        item.Status = "Ongoing";
        const event = {
          eventId: item.EventId,
        };
        this.postStatus(event);
      }
    });
  }

  checkDate(targetDateString) {
    return this.daysUntil(targetDateString) < 0;
  }

  daysUntil(targetDateString) {
    const parts = targetDateString.split(" ");
    const datePart = parts[0].split(".");
    const timePart = parts[1].split(":");

    const day = parseInt(datePart[0], 10);
    const month = parseInt(datePart[1], 10);
    const year = parseInt(datePart[2], 10);

    const hours = parseInt(timePart[0], 10);
    const minutes = parseInt(timePart[1], 10);

    const targetDate = new Date(year, month - 1, day, hours, minutes);
    const currentDate = new Date();

    const differenceMs = targetDate.getTime() - currentDate.getTime();
    if (differenceMs <= 0) {
      return -1;
    }
    const days = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
    return days;
  }

  formatDate(inputDate) {
    const date = new Date(this.convertDateFormat(inputDate));
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const hour = date.getUTCHours();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const formattedDate = `${day} ${monthNames[monthIndex]} ${hour} UTC`;

    return formattedDate;
  }

  convertDateFormat(inputDate) {
    const [datePart, timePart] = inputDate.split(" ");
    const [day, month, year] = datePart.split(".");
    const [hour, minute] = timePart.split(":");

    const isoDateString = `${year}-${month}-${day}T${hour}:${minute}:00`;
    return isoDateString;
  }

  getUpcomingEvents() {
    const { events } = this.state;
    const parent = document.getElementById("upeventsbox");
    let parentWidth = 250;
    if (parent) {
      parentWidth = parent.offsetWidth;
    }
    return events
      .filter((item) => item.EventStatus == "Upcoming")
      .slice(0, parentWidth % 250)
      .map((item) => (
        <a href={item.EventDescription ? item.EventDescription : "/"}>
          <div key={item.EventId}>
            <div className="relative bg-event group w-[250px] h-[366px] rounded-event overflow-hidden cursor-pointer snap-start">
              <div>
                <div className="absolute w-full h-event-img-box object-cover object-center transition-transform duration-500 group-hover:scale-110">
                  <img
                    src={variables.PHOTO_URL + item.EventPhotoFileName}
                    alt={item.EventName != null ? item.EventName : "Event.jpg"}
                  />
                </div>
                <div className="absolute z-10 w-full h-event-text-box event-text-container">
                  <span className="event-text event-text-1">
                    {item.EventTime != null
                      ? this.formatDate(item.EventTime)
                      : "XX.YY.ZZ AA:BB UTC"}
                  </span>
                  <span className="event-text event-text-2">
                    {item.EventName != null ? item.EventName : "Event"}
                    <span className="event-text-2a">
                      {" "}
                      -{" "}
                      {item.EventCommunity != null
                        ? item.EventCommunity
                        : "Injective"}
                    </span>
                  </span>
                  <span className="event-text event-text-3">
                    {item.EventRewards != null
                      ? item.EventRewards.trim()
                      : "Big rewards!"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </a>
      ));
  }

  render() {
    return (
      <section className="w-full left-0 mx-auto">
        <div className="flex items-end">
          <h2 className="font-semibold text-3xl font-semibold mt-5 adaptive-news">
            Upcoming Events
          </h2>
        </div>
        <div className="flex flex-col-reverse mt-11 sm:flex-row relative">
          <div
            id="upeventsbox"
            className="flex grow flex-wrap gap-5 self-start"
          >
            {this.getUpcomingEvents()}
          </div>
          <div className="grow hidden md:block"></div>
        </div>
      </section>
    );
  }
}
