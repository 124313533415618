import * as React from "react";
import "./App.css";
import { Component } from "react";
import { variables } from "./Variables";

export class News extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isAdmin !== this.props.isAdmin ||
      prevProps.wallet !== this.props.wallet
    ) {
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      news: [],
      newsId: 0,
      newsName: "",
      newsDescription: "",
      newsWord: "",
      newsButtonText: "",
      newsButtonLink: "",
      newsPhotoFileName: "",
      password: "",
      newsLogoFileName: "",
      timeoutId: null,
      errorAlertMessage: null,
    };
  }

  async fetchNews() {
    try {
      const response = await fetch(variables.API_URL + "news");
      if (!response.ok) {
        throw new Error("Failed to fetch news data");
      }
      const jsonData = await response.json();
      if (jsonData.value.length > 0) {
        await this.setState({ news: JSON.parse(jsonData.value) }, () => {});
      } else {
        console.error("No data received");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  componentDidMount() {
    this.fetchNews();
  }

  ErrorAlert() {
    return (
      <div className="container">
        {this.state.errorAlertMessage && (
          <div className="error">{this.state.errorAlertMessage}</div>
        )}
      </div>
    );
  }
  handleError(errorMessage) {
    if (errorMessage) {
      this.setState({ errorAlertMessage: errorMessage });
      if (this.state.timeoutId !== null) {
        clearTimeout(this.state.timeoutId);
      }
      const newTimeoutId = window.setTimeout(() => {
        this.setState({ errorAlertMessage: null });
      }, 5500);
      this.setState({ timeoutId: newTimeoutId });
    }
  }

  changeNewsName = (e) => {
    this.setState({ newsName: e.target.value });
  };

  changeNewsDescription = (e) => {
    this.setState({ newsDescription: e.target.value });
  };

  changeNewsWord = (e) => {
    this.setState({ newsWord: e.target.value });
  };

  changeNewsButtonText = (e) => {
    this.setState({ newsButtonText: e.target.value });
  };

  changeNewsButtonLink = (e) => {
    this.setState({ newsButtonLink: e.target.value });
  };

  changeNewsPhotoFileName = (e) => {
    this.setState({ newsPhotoFileName: e.target.value });
  };

  changePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  editClick(news) {
    this.setState({
      newsName: news.newsName,
      newsDescription: news.newsDescription,
      newsWord: news.newsWord,
      newsButtonText: news.newsButtonText,
      newsButtonLink: news.newsButtonLink,
      newsPhotoFileName: news.newsPhotoFileName,
    });
  }

  handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === "string") {
          this.setState({ newsPhotoFileName: result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  imageUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);

    fetch(variables.API_URL + "news/savefile", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ newsPhotoFileName: data.value });
      });
  };

  logoUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);

    fetch(variables.API_URL + "news/savefile", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ newsLogoFileName: data.value });
      });
  };

  postPutRequest(requestMethod, news) {
    const data = {
      news: {
        NewsId: news.newsId,
        NewsName: news.newsName,
        NewsDescription: news.newsDescription,
        NewsWord: news.newsWord,
        NewsButtonText: news.newsButtonText,
        NewsButtonLink: news.newsButtonLink,
        NewsPhotoFileName: news.newsPhotoFileName,
      },
      loginModel: {
        Wallet: this.props.wallet == null ? "injwallet" : this.props.wallet,
        Password: news.password,
      },
    };

    fetch(variables.API_URL + "news", {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          this.handleError("News data submitted successfully  !");
        } else {
          response.text().then((errorMessage) => {
            this.handleError("Failed to submit news data: " + errorMessage);
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.handleError("An error occurred while submitting news data.");
      });
  }

  resetState() {
    this.setState({
      newsId: 0,
      newsName: "",
      newsDescription: "",
      newsWord: "",
      newsButtonText: "",
      newsButtonLink: "",
      newsPhotoFileName: "",
      newsLogoFileName: "",
      password: "",
      timeoutId: null,
      errorAlertMessage: null,
    });
  }

  createClick(news) {
    if (
      news &&
      news.newsName &&
      news.newsDescription &&
      news.newsWord &&
      news.newsButtonText &&
      news.newsButtonLink
    ) {
      this.postPutRequest("POST", news);
      this.resetState();
    } else this.handleError("News parametrs cannot be null!");
  }

  updateClick(news) {
    this.postPutRequest("PUT", news);
    this.resetState();
    //window.location.reload();
  }

  getNews() {
    const { news } = this.state;
    return news.slice(0, 5).map((item, index) => (
      <div
        className="swiper-slide"
        id={"news" + index}
        style={{ width: "calc(100vw - 529.6px)", maxWidth: "1350px" }}
      >
        <div className="relative w-full h-full">
          <div className="absolute w-full h-full bg-cover bg-center z-[-1] overflow-hidden">
            <img
              src={variables.PHOTO_URL + item.NewsPhotoFileName}
              className="absolute w-full h-full object-cover"
              height="16"
              width="9"
            ></img>
          </div>
          <div className="absolute w-full h-full flex flex-col items-start justify-end p-12 bg-gradient-to-t md:bg-gradient-to-r from-page via-page/80 to-transparent font-display z-10">
            <div className="block text-accent font-semibold cursor-pointer">
              {item.NewsWord}
            </div>
            <div className="text-3xl font-semibold text-light">
              {item.NewsName}
            </div>
            <div className="mt-3.5 text-default/60 overflow-y-auto max-w-[500px]">
              {item.NewsDescription}
            </div>
            <div className="w-full mt-6 flex flex-col items-stretch sm:flex-row sm:items-end gap-2.5">
              <a
                className="!no-underline"
                target="_blank"
                href={item.NewsButtonLink}
              >
                <button
                  type="button"
                  className="px-4 py-2 text-sm border-transparent transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none w-full sm:w-auto bg-[#C62222] text-[#FAFAFA] hover:bg-[#E35151]"
                >
                  <span className="inline-flex items-center gap-1">
                    {item.NewsButtonText}
                  </span>
                </button>
              </a>
              {this.props.isAdmin ? this.getUpdateButton(index) : null}
            </div>
          </div>
        </div>
        {this.props.isAdmin ? (
          <div className="centered">{this.getUpdateMenu(item)}</div>
        ) : null}
      </div>
    ));
  }

  getNewsButtons() {
    const { news } = this.state;
    return news.slice(0, 5).map((item, index) => (
      <button
        id={"newsbut" + index}
        className={`flex items-center p-3.5 gap-3.5 border rounded-2xl transition-colors duration-300 relative border-card ${
          index == 0 ? "bg-card/60" : "bg-transparent"
        }`}
        onClick={() => {
          for (let i = 0; i < 5; i++) {
            if (i !== index) {
              const but = document.getElementById("newsbut" + i);
              if (but) {
                but.classList.remove("bg-card/60");
                but.classList.add("bg-transparent");
              }
            }
          }

          const currentBut = document.getElementById("newsbut" + index);
          if (currentBut) {
            currentBut.classList.remove("bg-transparent");
            currentBut.classList.add("bg-card/60");
          }

          const current = document.getElementById("news" + index);
          if (current) {
            const width = current.offsetWidth;

            const element = document.getElementById("newsBox1");
            if (element) {
              const currentStyle = window.getComputedStyle(element);

              const currentTransform =
                currentStyle.getPropertyValue("transform");
              const match = currentTransform.match(
                /translate3d\((-?\d+px),\s*-?\d+px,\s*-?\d+px\)/
              );
              const currentTranslateX = match ? match[1] : 0;
              const newTranslateX = 0 - width * index;
              const updatedTransform = currentTransform.replace(
                `translate3d(${currentTranslateX},`,
                `translate3d(${newTranslateX},`
              );

              element.style.transitionDuration = "300ms";
              element.style.transform = updatedTransform;

              element.setAttribute(
                "style",
                `transitionDuration:300ms; transform: translate3d(${newTranslateX}px, 0px, 0px);`
              );
              setTimeout(() => {
                element.setAttribute(
                  "style",
                  `transitionDuration:0ms; transform: translate3d(${newTranslateX}px, 0px, 0px);`
                );
              }, 300);
            }
          }
        }}
      >
        <div className="relative overflow-hidden w-[66px] h-[66px] aspect-square shrink-0 rounded-lg z-10">
          <img
            src={variables.PHOTO_URL + item.NewsLogoFileName}
            decoding="async"
            data-nimg="fill"
            className="aspect-square rounded-2xl"
            sizes="128px"
            style={{
              position: "absolute",
              inset: "0px",
              boxSizing: "border-box",
              padding: "0px",
              border: "none",
              margin: "auto",
              display: "block",
              width: "0px",
              height: "0px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <div className="w-[190px] text-base text-left font-display z-10">
          <div className="font-semibold block truncate text-light">
            {item.NewsWord}
          </div>
          <div className="block truncate text-light">{item.NewsName}</div>
        </div>
      </button>
    ));
  }

  getNewsSwiper() {
    const { news } = this.state;
    return news.slice(0, 5).map((item, index) => (
      <div
        id={"newsSwiper" + index}
        className="swiper-slide h-[550px] border border-card rounded-2xl overflow-hidden"
        style={{ marginRight: "20px" }}
      >
        <div className="adaptive-container relative w-full h-full">
          <div className=" absolute w-full h-full bg-cover bg-center z-[-1] overflow-hidden">
            <img
              src={variables.PHOTO_URL + item.NewsPhotoFileName}
              className="adaptive-photo absolute w-full h-full object-cover"
              height="16"
              width="9"
            ></img>
          </div>
          <div className=" absolute w-full h-full flex flex-col items-start justify-end p-12 bg-gradient-to-t md:bg-gradient-to-r from-page via-page/80 to-transparent font-display z-10">
            <div className="block text-accent font-semibold cursor-pointer">
              {item.NewsWord}
            </div>
            <div className="adaptive-name text-3xl font-semibold text-light">
              {item.NewsName}
            </div>
            <div className=" mt-3.5 text-default/60 overflow-y-auto max-w-[500px] adaptive-description">
              {item.NewsDescription}
            </div>
            <div className="adaptive-link w-full mt-6 flex flex-col items-stretch sm:flex-row sm:items-end gap-2.5">
              <a
                className="!no-underline"
                target="_blank"
                href={item.NewsButtonLink}
              >
                <button
                  type="button"
                  className="adaptive-button px-4 py-2 text-sm border-transparent transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none w-full sm:w-auto bg-[#C62222] text-[#FAFAFA] hover:bg-[#E35151]"
                >
                  <span className="inline-flex items-center gap-1">
                    {item.NewsButtonText}
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  getNewsSpans() {
    const { news } = this.state;
    return news.slice(0, 5).map((_, index) => (
      <span
        key={index}
        id={"newsspan" + index}
        className={`swiper-pagination-bullet ${
          index === 0 ? "swiper-pagination-bullet-active" : ""
        }`}
        onClick={() => {
          // Remove active class from all spans except the current one
          for (let i = 0; i < 5; i++) {
            if (i !== index) {
              const but = document.getElementById("newsspan" + i);
              if (but) {
                but.classList.remove("swiper-pagination-bullet-active");
              }
            }
          }

          // Add active class to the current span
          const currentBut = document.getElementById("newsspan" + index);
          if (currentBut) {
            currentBut.classList.add("swiper-pagination-bullet-active");
          }

          // Handle the sliding animation
          const current = document.getElementById("newsSwiper" + index);
          if (current) {
            const width = current.offsetWidth;
            const element = document.getElementById("newsBox2");
            if (element) {
              const newTranslateX = 0 - width * index;

              element.style.transitionDuration = "300ms";
              element.style.transform = `translate3d(${newTranslateX}px, 0px, 0px)`;

              setTimeout(() => {
                element.style.transitionDuration = "0ms";
                element.style.transform = `translate3d(${newTranslateX}px, 0px, 0px)`;
              }, 300);
            }
          }
        }}
      ></span>
    ));
  }

  getUpdateButton(index) {
    return (
      <button
        type="button"
        className="px-4 py-2 text-sm border-transparent transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none w-full sm:w-auto bg-[#C62222] text-[#FAFAFA] hover:bg-[#E35151]"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        onClick={() => {
          let elements = document.getElementsByClassName("container-news");

          for (let i = 0; i < elements.length; i++) {
            if (index != i && elements[i].classList.contains("showmenu")) {
              elements[i].classList.toggle("showmenu");
            }
          }

          document
            .getElementsByClassName("container-news")
            [index].classList.toggle("showmenu");
          this.resetState();
        }}
      >
        <span className="inline-flex items-center gap-1">Update</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-pencil-square"
          viewBox="0 0 16 16"
        >
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
          <path
            fillRule="evenodd"
            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
          />
        </svg>
      </button>
    );
  }
  getUpdateMenu(item) {
    return (
      <div
        className="container-news display-none"
        style={{
          maxWidth: "2200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "rgb(30, 40, 60)",
        }}
      >
        <div className="left-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Name:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.NewsName}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsName(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Description:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.NewsDescription}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsDescription(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Key Word:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.NewsWord}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsWord(event)}
            />
          </div>
        </div>
        <div className="right-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Link:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.NewsButtonLink}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsButtonLink(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Text:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.NewsButtonText}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsButtonText(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.newsPhotoFileName !== "" &&
            this.state.newsPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.newsPhotoFileName}
              />
            ) : item.NewsPhotoFileName !== "" &&
              item.NewsPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + item.NewsPhotoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.imageUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.newsLogoFileName !== "" &&
            this.state.newsLogoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.newsLogoFileName}
              />
            ) : item.NewsLogoFileName !== "" &&
              item.NewsLogoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + item.NewsLogoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.logoUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc" }}>Password:</p>
            <input
              type="password"
              id="inputText"
              placeholder="  enter password"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changePassword(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <button
              type="button"
              className="click"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                const news = {
                  newsId: item.NewsId,
                  newsName:
                    this.state.newsName === ""
                      ? item.NewsName
                      : this.state.newsName,
                  newsDescription:
                    this.state.newsDescription === ""
                      ? item.NewsDescription
                      : this.state.newsDescription,
                  newsButtonLink:
                    this.state.newsButtonLink === ""
                      ? item.NewsButtonLink
                      : this.state.newsButtonLink,
                  newsButtonText:
                    this.state.newsButtonText === ""
                      ? item.NewsButtonText
                      : this.state.newsButtonText,
                  newsWord:
                    this.state.newsWord === ""
                      ? item.NewsWord
                      : this.state.newsWord,
                  newsPhotoFileName:
                    this.state.newsPhotoFileName != undefined &&
                    this.state.newsPhotoFileName != "" &&
                    this.state.newsPhotoFileName != null
                      ? this.state.newsPhotoFileName
                      : item.NewsPhotoFileName,
                  newsLogoFileName:
                    this.state.newsLogoFileName != undefined &&
                    this.state.newsLogoFileName != "" &&
                    this.state.newsLogoFileName != null
                      ? this.state.newsLogoFileName
                      : item.NewsLogoFileName,
                  password: this.state.password,
                  wallet:
                    this.props.wallet == null ? "injwallet" : this.props.wallet,
                };
                this.updateClick(news);
              }}
            >
              Update
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }

  createMenu() {
    return (
      <div
        id="createMenu"
        className="container-events display-none"
        style={{
          maxWidth: "2200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "rgb(30, 40, 60)",
        }}
      >
        <div className="left-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Name:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsName(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Description:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsDescription(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Key Word:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsWord(event)}
            />
          </div>
        </div>
        <div className="right-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Link:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsButtonLink(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Text:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeNewsButtonText(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.newsPhotoFileName !== "" &&
            this.state.newsPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.newsPhotoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.imageUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.newsLogoFileName !== "" &&
            this.state.newsLogoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.newsLogoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.logoUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc" }}>Password:</p>
            <input
              type="password"
              id="inputText"
              placeholder="  enter password"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changePassword(event)}
            />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <button
            type="button"
            className="click"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => {
              const news = {
                newsId: 0,
                newsName: this.state.newsName,
                newsDescription: this.state.newsDescription,
                newsButtonLink: this.state.newsButtonLink,
                newsButtonText: this.state.newsButtonText,
                newsWord: this.state.newsWord,
                newsPhotoFileName: this.state.newsPhotoFileName,
                newsLogoFileName: this.state.newsLogoFileName,
                password: this.state.password,
                wallet:
                  this.props.wallet == null ? "injwallet" : this.props.wallet,
              };
              this.createClick(news);
            }}
          >
            Create
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
  getCreateButtonMenu() {
    return (
      <div>
        {this.createMenu()}
        <button
          type="button"
          className="click"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            const doc = document.getElementById("createMenu");
            if (doc) {
              doc.classList.toggle("showmenu");
            }
            this.resetState();
          }}
        >
          Add News
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fillRule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            />
          </svg>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="absolute">{this.ErrorAlert()}</div>
        <div className="hidden xl:flex w-full gap-5">
          <div className="grow h-[550px] border border-card rounded-2xl overflow-hidden">
            <div className="swiper h-full">
              <div
                id="newsBox1"
                className="swiper-wrapper"
                style={{
                  transitionDuration: "0ms",
                  transform: "translate3d(0px, 0px, 0px)",
                }}
              >
                {this.getNews()}
              </div>
            </div>
          </div>
          <div className="w-[300px] shrink-0 flex flex-col gap-3.5 h-[550px] trove-scrollbar">
            {this.getNewsButtons()}
          </div>
        </div>
        <div className="xl:hidden w-[calc(100%-30px)] mx-auto">
          <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events h-[600px] xl:hidden pb-12 overflow-visible swiper-backface-hidden">
            <div
              id="newsBox2"
              className="swiper-wrapper"
              style={{
                transitionDuration: "0ms",
                transform: "translate3d(0px, 0px, 0px)",
              }}
            >
              {this.getNewsSwiper()}
            </div>
            <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
              {this.getNewsSpans()}
            </div>
          </div>
        </div>
        {this.props.isAdmin ? this.getCreateButtonMenu() : null}
      </div>
    );
  }
}
