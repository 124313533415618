import "./App.css";
import { Component } from "react";

export class Partners extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isAdmin !== this.props.isAdmin ||
      prevProps.wallet !== this.props.wallet
    ) {
    }
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        data-overlay-container="true"
        className="manimate-easy"
        style={{ width: "calc(100vw - 80px)", float: "right" }}
      >
        <div className="h-screen antialiased">
          <div
            className="min-h-[calc(100%-80px)] lg:w-80px w-full relative flex flex-col transition-all duration-700 lg:w-[calc(100%-80px)]"
            style={{ backgroundColor: "#0b111b" }}
          >
            <div className="w-full flex-1 flex flex-col max-w-full ">
              <main className="grow min-h-[calc(100%-80px)] flex flex-col">
                <section
                  className="w-full lg:w-[calc(100vw-var(--sidebar-width)-20px)] mx-auto px-51 md:px-16 max-w-[1800px]"
                  style={{ marginTop: "1.25rem" }}
                >
                  <div>
                    <div className="flex justify-between">
                      <h2 className="font-semibold text-3xl font-semibold mt-5 center-titles">
                        Partners
                      </h2>
                    </div>
                    <div className="pb-10 mt-11 flex flex-wrap gap-y-5 gap-x-5 overflow-y-hidden fix-partners">
                      <div className="snap-center">
                        <div
                          className="font-display mr-[-20px] font-bold drop-shadow-lg shadow-white text-page text-[180px]"
                          id="text-shadow: rgba(255, 255, 255, 0.2) 2px 2px 10px, rgb(75, 85, 99) 0px 0px 2px;"
                        ></div>
                        <div>
                          <div className="relative group w-[220px] h-[312px] rounded-lg border-2 border-card overflow-hidden cursor-pointer snap-start">
                            <a href="https://kage.finance/">
                              <div className="absolute h-full w-full object-cover object-center transition-transform duration-500 group-hover:scale-110">
                                <img
                                  src="/assets/kage.png"
                                  decoding="async"
                                  data-nimg="fill"
                                  sizes="312px"
                                  className="ayn-img"
                                  alt="Kage Finance"
                                />
                              </div>
                              <div className="absolute z-10 p-4 w-full h-full flex flex-col gap-2.5 bg-gradient-to-t from-black/50 from-10% via-transparent via-20% to-transparent">
                                <div className="mt-auto flex justify-between items-center">
                                  <div className="ml-auto items-center text-sm flex justify-between">
                                    <div
                                      className=""
                                      data-tooltipped=""
                                      aria-describedby="tippy-tooltip-12"
                                      id="display: inline;"
                                    >
                                      <div className="p-2 bg-page/80 hover:bg-page/50 rounded">
                                        <svg
                                          aria-hidden="true"
                                          focusable="false"
                                          data-prefix="fas"
                                          data-icon="globe"
                                          className="svg-inline--fa fa-globe w-4 h-4 rounded"
                                          role="img"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 512 512"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M352 256C352 278.2 350.8 299.6 348.7 320H163.3C161.2 299.6 159.1 278.2 159.1 256C159.1 233.8 161.2 212.4 163.3 192H348.7C350.8 212.4 352 233.8 352 256zM503.9 192C509.2 212.5 512 233.9 512 256C512 278.1 509.2 299.5 503.9 320H380.8C382.9 299.4 384 277.1 384 256C384 234 382.9 212.6 380.8 192H503.9zM493.4 160H376.7C366.7 96.14 346.9 42.62 321.4 8.442C399.8 29.09 463.4 85.94 493.4 160zM344.3 160H167.7C173.8 123.6 183.2 91.38 194.7 65.35C205.2 41.74 216.9 24.61 228.2 13.81C239.4 3.178 248.7 0 256 0C263.3 0 272.6 3.178 283.8 13.81C295.1 24.61 306.8 41.74 317.3 65.35C328.8 91.38 338.2 123.6 344.3 160H344.3zM18.61 160C48.59 85.94 112.2 29.09 190.6 8.442C165.1 42.62 145.3 96.14 135.3 160H18.61zM131.2 192C129.1 212.6 127.1 234 127.1 256C127.1 277.1 129.1 299.4 131.2 320H8.065C2.8 299.5 0 278.1 0 256C0 233.9 2.8 212.5 8.065 192H131.2zM194.7 446.6C183.2 420.6 173.8 388.4 167.7 352H344.3C338.2 388.4 328.8 420.6 317.3 446.6C306.8 470.3 295.1 487.4 283.8 498.2C272.6 508.8 263.3 512 255.1 512C248.7 512 239.4 508.8 228.2 498.2C216.9 487.4 205.2 470.3 194.7 446.6H194.7zM190.6 503.6C112.2 482.9 48.59 426.1 18.61 352H135.3C145.3 415.9 165.1 469.4 190.6 503.6V503.6zM321.4 503.6C346.9 469.4 366.7 415.9 376.7 352H493.4C463.4 426.1 399.8 482.9 321.4 503.6V503.6z"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="flex">
                            <div className="pt-4 cursor-pointer text-1xl text-white font-semibold font-display flex-wrap">
                              <a href="https://kage.finance/">Kage Finance</a>
                            </div>
                          </div>
                          <div className="bg-[#19253A] w-fit px-2 py-1 mt-2 rounded-[4px] font-bold text-xs font-body">
                            DEX
                          </div>
                        </div>
                      </div>
                      <div className="snap-center">
                        <div
                          className="font-display mr-[-20px] font-bold drop-shadow-lg shadow-white text-page text-[180px]"
                          id="text-shadow: rgba(255, 255, 255, 0.2) 2px 2px 10px, rgb(75, 85, 99) 0px 0px 2px;"
                        ></div>
                        <div>
                          <div className="relative group w-[220px] h-[312px] rounded-lg border-2 border-card overflow-hidden cursor-pointer snap-start">
                            <a href="https://theinjhallas.com/">
                              <div className="absolute h-full w-full object-cover object-center transition-transform duration-500 group-hover:scale-110">
                                <img
                                  src="/assets/image33.png "
                                  decoding="async"
                                  data-nimg="fill"
                                  sizes="312px"
                                  className="ayn-img"
                                  alt="Injective Hallas"
                                />
                              </div>
                              <div className="absolute z-10 p-4 w-full h-full flex flex-col gap-2.5 bg-gradient-to-t from-black/50 from-10% via-transparent via-20% to-transparent">
                                <div className="flex gap-2 items-center"></div>
                                <div className="mt-auto flex justify-between items-center">
                                  <div className="ml-auto items-center text-sm flex justify-between">
                                    <div
                                      className=""
                                      data-tooltipped=""
                                      aria-describedby="tippy-tooltip-13"
                                      id="display: inline;"
                                    >
                                      <div className="p-2 bg-page/80 hover:bg-page/50 rounded">
                                        <svg
                                          aria-hidden="true"
                                          focusable="false"
                                          data-prefix="fas"
                                          data-icon="globe"
                                          className="svg-inline--fa fa-globe w-4 h-4 rounded"
                                          role="img"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 512 512"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M352 256C352 278.2 350.8 299.6 348.7 320H163.3C161.2 299.6 159.1 278.2 159.1 256C159.1 233.8 161.2 212.4 163.3 192H348.7C350.8 212.4 352 233.8 352 256zM503.9 192C509.2 212.5 512 233.9 512 256C512 278.1 509.2 299.5 503.9 320H380.8C382.9 299.4 384 277.1 384 256C384 234 382.9 212.6 380.8 192H503.9zM493.4 160H376.7C366.7 96.14 346.9 42.62 321.4 8.442C399.8 29.09 463.4 85.94 493.4 160zM344.3 160H167.7C173.8 123.6 183.2 91.38 194.7 65.35C205.2 41.74 216.9 24.61 228.2 13.81C239.4 3.178 248.7 0 256 0C263.3 0 272.6 3.178 283.8 13.81C295.1 24.61 306.8 41.74 317.3 65.35C328.8 91.38 338.2 123.6 344.3 160H344.3zM18.61 160C48.59 85.94 112.2 29.09 190.6 8.442C165.1 42.62 145.3 96.14 135.3 160H18.61zM131.2 192C129.1 212.6 127.1 234 127.1 256C127.1 277.1 129.1 299.4 131.2 320H8.065C2.8 299.5 0 278.1 0 256C0 233.9 2.8 212.5 8.065 192H131.2zM194.7 446.6C183.2 420.6 173.8 388.4 167.7 352H344.3C338.2 388.4 328.8 420.6 317.3 446.6C306.8 470.3 295.1 487.4 283.8 498.2C272.6 508.8 263.3 512 255.1 512C248.7 512 239.4 508.8 228.2 498.2C216.9 487.4 205.2 470.3 194.7 446.6H194.7zM190.6 503.6C112.2 482.9 48.59 426.1 18.61 352H135.3C145.3 415.9 165.1 469.4 190.6 503.6V503.6zM321.4 503.6C346.9 469.4 366.7 415.9 376.7 352H493.4C463.4 426.1 399.8 482.9 321.4 503.6V503.6z"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="flex">
                            <div className="pt-4 cursor-pointer text-1xl text-white font-semibold font-display flex-wrap">
                              <a href="https://theinjhallas.com/">
                                Injective Hallas
                              </a>
                            </div>
                          </div>
                          <div className="bg-[#19253A] w-fit px-2 py-1 mt-2 rounded-[4px] font-bold text-xs font-body">
                            NFT
                          </div>
                        </div>
                      </div>
                      <div className="snap-center">
                        <div
                          className="font-display mr-[-20px] font-bold drop-shadow-lg shadow-white text-page text-[180px]"
                          id="text-shadow: rgba(255, 255, 255, 0.2) 2px 2px 10px, rgb(75, 85, 99) 0px 0px 2px;"
                        ></div>
                        <div>
                          <div className="relative group w-[220px] h-[312px] rounded-lg border-2 border-card overflow-hidden cursor-pointer snap-start">
                            <a href="https://beta.quantumfi.app/">
                              <div className="absolute h-full w-full object-cover object-center transition-transform duration-500 group-hover:scale-110">
                                <img
                                  src="/assets/quantum.png"
                                  decoding="async"
                                  data-nimg="fill"
                                  sizes="312px"
                                  className="ayn-img"
                                  alt="Injective Hallas"
                                />
                              </div>
                              <div className="absolute z-10 p-4 w-full h-full flex flex-col gap-2.5 bg-gradient-to-t from-black/50 from-10% via-transparent via-20% to-transparent">
                                <div className="flex gap-2 items-center"></div>
                                <div className="mt-auto flex justify-between items-center">
                                  <div className="ml-auto items-center text-sm flex justify-between">
                                    <div
                                      className=""
                                      data-tooltipped=""
                                      aria-describedby="tippy-tooltip-13"
                                      id="display: inline;"
                                    >
                                      <div className="p-2 bg-page/80 hover:bg-page/50 rounded">
                                        <svg
                                          aria-hidden="true"
                                          focusable="false"
                                          data-prefix="fas"
                                          data-icon="globe"
                                          className="svg-inline--fa fa-globe w-4 h-4 rounded"
                                          role="img"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 512 512"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M352 256C352 278.2 350.8 299.6 348.7 320H163.3C161.2 299.6 159.1 278.2 159.1 256C159.1 233.8 161.2 212.4 163.3 192H348.7C350.8 212.4 352 233.8 352 256zM503.9 192C509.2 212.5 512 233.9 512 256C512 278.1 509.2 299.5 503.9 320H380.8C382.9 299.4 384 277.1 384 256C384 234 382.9 212.6 380.8 192H503.9zM493.4 160H376.7C366.7 96.14 346.9 42.62 321.4 8.442C399.8 29.09 463.4 85.94 493.4 160zM344.3 160H167.7C173.8 123.6 183.2 91.38 194.7 65.35C205.2 41.74 216.9 24.61 228.2 13.81C239.4 3.178 248.7 0 256 0C263.3 0 272.6 3.178 283.8 13.81C295.1 24.61 306.8 41.74 317.3 65.35C328.8 91.38 338.2 123.6 344.3 160H344.3zM18.61 160C48.59 85.94 112.2 29.09 190.6 8.442C165.1 42.62 145.3 96.14 135.3 160H18.61zM131.2 192C129.1 212.6 127.1 234 127.1 256C127.1 277.1 129.1 299.4 131.2 320H8.065C2.8 299.5 0 278.1 0 256C0 233.9 2.8 212.5 8.065 192H131.2zM194.7 446.6C183.2 420.6 173.8 388.4 167.7 352H344.3C338.2 388.4 328.8 420.6 317.3 446.6C306.8 470.3 295.1 487.4 283.8 498.2C272.6 508.8 263.3 512 255.1 512C248.7 512 239.4 508.8 228.2 498.2C216.9 487.4 205.2 470.3 194.7 446.6H194.7zM190.6 503.6C112.2 482.9 48.59 426.1 18.61 352H135.3C145.3 415.9 165.1 469.4 190.6 503.6V503.6zM321.4 503.6C346.9 469.4 366.7 415.9 376.7 352H493.4C463.4 426.1 399.8 482.9 321.4 503.6V503.6z"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="flex">
                            <div className="pt-4 cursor-pointer text-1xl text-white font-semibold font-display flex-wrap">
                              <a href="https://beta.quantumfi.app/">
                                Quantum Finance
                              </a>
                            </div>
                          </div>
                          <div className="bg-[#19253A] w-fit px-2 py-1 mt-2 rounded-[4px] font-bold text-xs font-body">
                            NFT Gated Staking
                          </div>
                        </div>
                      </div>
                      <div className="snap-center">
                        <div
                          className="font-display mr-[-20px] font-bold drop-shadow-lg shadow-white text-page text-[180px]"
                          id="text-shadow: rgba(255, 255, 255, 0.2) 2px 2px 10px, rgb(75, 85, 99) 0px 0px 2px;"
                        ></div>
                        <div>
                          <div className="relative group w-[220px] h-[312px] rounded-lg border-2 border-card overflow-hidden cursor-pointer snap-start">
                            <a href="https://balbesique.art/">
                              <div className="absolute h-full w-full object-cover object-center transition-transform duration-500 group-hover:scale-110">
                                <img
                                  src="/assets/balbes.png"
                                  decoding="async"
                                  data-nimg="fill"
                                  sizes="312px"
                                  className="ayn-img"
                                  alt="Quantum"
                                />
                              </div>
                              <div className="absolute z-10 p-4 w-full h-full flex flex-col gap-2.5 bg-gradient-to-t from-black/50 from-10% via-transparent via-20% to-transparent">
                                <div className="flex gap-2 items-center"></div>
                                <div className="mt-auto flex justify-between items-center">
                                  <div className="ml-auto items-center text-sm flex justify-between">
                                    <div
                                      className=""
                                      data-tooltipped=""
                                      aria-describedby="tippy-tooltip-13"
                                      id="display: inline;"
                                    >
                                      <div className="p-2 bg-page/80 hover:bg-page/50 rounded">
                                        <svg
                                          aria-hidden="true"
                                          focusable="false"
                                          data-prefix="fas"
                                          data-icon="globe"
                                          className="svg-inline--fa fa-globe w-4 h-4 rounded"
                                          role="img"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 512 512"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M352 256C352 278.2 350.8 299.6 348.7 320H163.3C161.2 299.6 159.1 278.2 159.1 256C159.1 233.8 161.2 212.4 163.3 192H348.7C350.8 212.4 352 233.8 352 256zM503.9 192C509.2 212.5 512 233.9 512 256C512 278.1 509.2 299.5 503.9 320H380.8C382.9 299.4 384 277.1 384 256C384 234 382.9 212.6 380.8 192H503.9zM493.4 160H376.7C366.7 96.14 346.9 42.62 321.4 8.442C399.8 29.09 463.4 85.94 493.4 160zM344.3 160H167.7C173.8 123.6 183.2 91.38 194.7 65.35C205.2 41.74 216.9 24.61 228.2 13.81C239.4 3.178 248.7 0 256 0C263.3 0 272.6 3.178 283.8 13.81C295.1 24.61 306.8 41.74 317.3 65.35C328.8 91.38 338.2 123.6 344.3 160H344.3zM18.61 160C48.59 85.94 112.2 29.09 190.6 8.442C165.1 42.62 145.3 96.14 135.3 160H18.61zM131.2 192C129.1 212.6 127.1 234 127.1 256C127.1 277.1 129.1 299.4 131.2 320H8.065C2.8 299.5 0 278.1 0 256C0 233.9 2.8 212.5 8.065 192H131.2zM194.7 446.6C183.2 420.6 173.8 388.4 167.7 352H344.3C338.2 388.4 328.8 420.6 317.3 446.6C306.8 470.3 295.1 487.4 283.8 498.2C272.6 508.8 263.3 512 255.1 512C248.7 512 239.4 508.8 228.2 498.2C216.9 487.4 205.2 470.3 194.7 446.6H194.7zM190.6 503.6C112.2 482.9 48.59 426.1 18.61 352H135.3C145.3 415.9 165.1 469.4 190.6 503.6V503.6zM321.4 503.6C346.9 469.4 366.7 415.9 376.7 352H493.4C463.4 426.1 399.8 482.9 321.4 503.6V503.6z"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="flex">
                            <div className="pt-4 cursor-pointer text-1xl text-white font-semibold font-display flex-wrap">
                              <a href="https://talis.art">Balbesique</a>
                            </div>
                          </div>
                          <div className="bg-[#19253A] w-fit px-2 py-1 mt-2 rounded-[4px] font-bold text-xs font-body">
                            NFT
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <footer className="mt-auto" aria-labelledby="footer-heading">
                  <section className="w-full lg:w-[calc(100vw-var(--sidebar-width)-20px)] mx-auto px-51 md:px-16 max-w-[1800px] py-20">
                    <div className="flex items-start flex-col lg:flex-row gap-11">
                      <div className="flex-1 adaptive-news">
                        <svg
                          width="252"
                          height="78"
                          viewBox="0 0 252 78"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M68 45.5875H75.1222C75.1222 48.3625 77.4962 49.6375 86.5177 49.6375C93.9959 49.6375 96.2513 48.85 96.2513 46.7875C96.2513 44.6125 94.6686 44.2375 85.6867 43.825C73.6186 43.3 68.8309 41.5 68.8309 35.6125C68.8309 29.8375 75.043 28 85.4098 28C95.8556 28 102.186 30.475 102.186 36.9625H95.0643C95.0643 34.075 92.0176 33.3625 84.6184 33.3625C77.6149 33.3625 75.9531 34 75.9531 35.95C75.9531 37.975 77.6149 38.35 85.6867 38.8C96.6074 39.3625 103.373 39.925 103.373 46.5625C103.373 53.575 96.37 55 86.122 55C74.7661 55 68 53.1625 68 45.5875Z"
                            fill="white"
                          />
                          <path
                            d="M127.716 28.375C134.007 28.375 138.32 32.1625 138.32 38.0125C138.32 43.8625 134.007 47.6125 127.716 47.6125H113.076V54.625H105.954V28.375H127.716ZM125.738 41.95C127.874 41.95 131.198 41.95 131.198 38.0125C131.198 34.0375 127.874 34.0375 125.738 34.0375H113.076V41.95H125.738Z"
                            fill="white"
                          />
                          <path
                            d="M177.411 54.625L174.443 49.0375L159.568 55L145.225 49.0375L142.257 54.625H134.423L148.707 28.375H170.961L185.285 54.625H177.411ZM147.876 44.0125L159.568 49.975L171.792 44.0125L167.812 33.2708L159.568 36.9625L151.325 33.2708L147.876 44.0125Z"
                            fill="white"
                          />
                          <path
                            d="M183.423 41.4625C183.423 32.7625 188.646 28 200.833 28C211.872 28 217.411 31.975 218.124 39.55H210.883C210.21 35.3125 206.768 33.6625 200.833 33.6625C193.592 33.6625 190.545 36.1 190.545 41.4625C190.545 46.8625 193.631 49.3375 200.833 49.3375C206.728 49.3375 210.21 47.65 210.883 43.375H218.124C217.411 50.9875 211.872 55 200.833 55C188.646 55 183.423 50.2 183.423 41.4625Z"
                            fill="white"
                          />
                          <path
                            d="M250 33.7375H227.842V39.0625H250V43.9375H227.842V49.2625H250V54.625H220.72V28.375H250V33.7375Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M37.9078 32.7631L48.2125 36.3699C48.6717 36.5503 49 37.0011 49 37.5196C49 38.0381 48.6939 38.5115 48.2125 38.6693L37.9078 42.2761C34.1229 43.6061 31.3007 46.8975 30.5132 50.9328L26.1594 73.0697C26.0503 73.6335 25.5689 74.039 25 74.039C24.4311 74.039 23.9497 73.6335 23.8403 73.0697L19.4868 50.9328C18.699 46.8975 15.8768 43.6061 12.0922 42.2761L1.78747 38.6693C1.32826 38.4889 1 38.0381 1 37.5196C1 37.0011 1.30614 36.5276 1.78747 36.3699L12.0922 32.7631C15.8768 31.433 18.699 28.1417 19.4868 24.1064L23.8403 1.96934C23.9497 1.40576 24.4311 1 25 1C25.5689 1 26.0503 1.40576 26.1594 1.96934L30.5132 24.1064C31.3007 28.1417 34.1229 31.433 37.9078 32.7631ZM32.5296 34.8199L38.5407 36.867C38.8086 36.9694 39.0001 37.2253 39.0001 37.5195C39.0001 37.8138 38.8215 38.0825 38.5407 38.1721L32.5296 40.2192C30.3218 40.9741 28.6755 42.8421 28.2161 45.1324L25.6764 57.6966C25.6127 58.0166 25.332 58.2468 25.0001 58.2468C24.6682 58.2468 24.3874 58.0166 24.3236 57.6966L21.7841 45.1324C21.3245 42.8421 19.6782 40.9741 17.4705 40.2192L11.4595 38.1721C11.1916 38.0697 11.0001 37.8138 11.0001 37.5195C11.0001 37.2253 11.1787 36.9565 11.4595 36.867L17.4705 34.8199C19.6782 34.065 21.3245 32.1969 21.7841 29.9067L24.3236 17.3424C24.3874 17.0225 24.6682 16.7922 25.0001 16.7922C25.332 16.7922 25.6127 17.0225 25.6764 17.3424L28.2161 29.9067C28.6755 32.1969 30.3218 34.065 32.5296 34.8199Z"
                            fill="white"
                          />
                        </svg>

                        <p className="mt-6 max-w-[450px] font-display text-muted-light">
                          Create, compete and earn rewards on Space - our
                          community website.
                        </p>
                      </div>
                    </div>
                    <div className="mt-11 flex justify-end fix-top">
                      <button
                        onClick={() =>
                          window.scrollBy({
                            top: -999999,
                            left: 0,
                            behavior: "smooth",
                          })
                        }
                        type="button"
                        className="px-4 py-2 text-sm text-default bg-muted/20 hover:bg-muted/30 border-transparent backdrop-blur transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none"
                      >
                        Back to top
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                          className="ml-2 w-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </section>
                </footer>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
