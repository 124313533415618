import * as React from "react";
import "./App.css";
import { Component } from "react";
import { variables } from "./Variables";

export class Updates extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isAdmin !== this.props.isAdmin ||
      prevProps.wallet !== this.props.wallet
    ) {
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      updates: [],
      updatesId: 0,
      updatesName: "",
      updatesDescription: "",
      updatesWord: "",
      updatesButtonText: "",
      updatesButtonLink: "",
      updatesPhotoFileName: "",
      password: "",
      timeoutId: null,
      errorAlertMessage: null,
    };
  }

  async fetchUpdates() {
    try {
      const response = await fetch(variables.API_URL + "updates");
      if (!response.ok) {
        throw new Error("Failed to fetch updates data");
      }
      const jsonData = await response.json();
      if (jsonData.value.length > 0) {
        await this.setState({ updates: JSON.parse(jsonData.value) }, () => {});
      } else {
        console.error("No data received");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  componentDidMount() {
    this.fetchUpdates();
  }

  ErrorAlert() {
    return (
      <div className="container">
        {this.state.errorAlertMessage && (
          <div className="error">{this.state.errorAlertMessage}</div>
        )}
      </div>
    );
  }
  handleError(errorMessage) {
    if (errorMessage) {
      this.setState({ errorAlertMessage: errorMessage });
      if (this.state.timeoutId !== null) {
        clearTimeout(this.state.timeoutId);
      }
      const newTimeoutId = window.setTimeout(() => {
        this.setState({ errorAlertMessage: null });
      }, 5500);
      this.setState({ timeoutId: newTimeoutId });
    }
  }

  changeUpdatesName = (e) => {
    this.setState({ updatesName: e.target.value });
  };

  changeUpdatesDescription = (e) => {
    this.setState({ updatesDescription: e.target.value });
  };

  changeUpdatesWord = (e) => {
    this.setState({ updatesWord: e.target.value });
  };

  changeUpdatesButtonText = (e) => {
    this.setState({ updatesButtonText: e.target.value });
  };

  changeUpdatesButtonLink = (e) => {
    this.setState({ updatesButtonLink: e.target.value });
  };

  changeUpdatesPhotoFileName = (e) => {
    this.setState({ updatesPhotoFileName: e.target.value });
  };

  changePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  editClick(updates) {
    this.setState({
      updatesName: updates.updatesName,
      updatesDescription: updates.updatesDescription,
      updatesWord: updates.updatesWord,
      updatesButtonText: updates.updatesButtonText,
      updatesButtonLink: updates.updatesButtonLink,
      updatesPhotoFileName: updates.updatesPhotoFileName,
    });
  }

  handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === "string") {
          this.setState({ updatesPhotoFileName: result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  imageUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);

    fetch(variables.API_URL + "updates/savefile", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ updatesPhotoFileName: data.value });
      });
  };

  postPutRequest(requestMethod, updates) {
    const data = {
      updates: {
        UpdatesId: updates.updatesId,
        UpdatesName: updates.updatesName,
        UpdatesDescription: updates.updatesDescription,
        UpdatesWord: updates.updatesWord,
        UpdatesButtonText: updates.updatesButtonText,
        UpdatesButtonLink: updates.updatesButtonLink,
        UpdatesPhotoFileName: updates.updatesPhotoFileName,
      },
      loginModel: {
        Wallet: this.props.wallet == null ? "injwallet" : this.props.wallet,
        Password: updates.password,
      },
    };

    fetch(variables.API_URL + "updates", {
      method: requestMethod,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          this.handleError("Updates data submitted successfully  !");
        } else {
          response.text().then((errorMessage) => {
            this.handleError("Failed to submit updates data: " + errorMessage);
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.handleError("An error occurred while submitting updates data.");
      });
  }

  resetState() {
    this.setState({
      updatesId: 0,
      updatesName: "",
      updatesDescription: "",
      updatesWord: "",
      updatesButtonText: "",
      updatesButtonLink: "",
      updatesPhotoFileName: "",
      password: "",
      timeoutId: null,
      errorAlertMessage: null,
    });
  }

  createClick(updates) {
    if (
      updates &&
      updates.updatesName &&
      updates.updatesDescription &&
      updates.updatesWord &&
      updates.updatesButtonText &&
      updates.updatesButtonLink
    ) {
      this.postPutRequest("POST", updates);
      this.resetState();
    } else this.handleError("Updates parametrs cannot be null!");
  }

  updateClick(updates) {
    this.postPutRequest("PUT", updates);
    this.resetState();
    //window.location.reload();
  }

  getUpdates() {
    const { updates } = this.state;
    return updates.slice(0, 3).map((item, index) => (
      <div
        id={"updates" + index}
        className="flex flex-col z-10 items-center justify-center text-center space-y-4 2xl:space-y-6"
      >
        <div className="max-w-eco ecosystem-container">
          <div className="max-w-eco eco-container-bottom ">
            <div className="eco-logo">
              <svg
                width="20"
                height="30"
                viewBox="0 0 20 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.3783 13.0464L19.6719 14.5278C19.8632 14.6019 20 14.7871 20 15C20 15.213 19.8724 15.4075 19.6719 15.4723L15.3783 16.9537C13.8012 17.5 12.6253 18.8519 12.2972 20.5094L10.4831 29.6019C10.4376 29.8334 10.2371 30 10 30C9.76294 30 9.56239 29.8334 9.5168 29.6019L7.70283 20.5094C7.37459 18.8519 6.19866 17.5 4.62175 16.9537L0.328114 15.4723C0.136776 15.3982 0 15.213 0 15C0 14.7871 0.127558 14.5926 0.328114 14.5278L4.62175 13.0464C6.19866 12.5 7.37459 11.1482 7.70283 9.49072L9.5168 0.398145C9.56239 0.166662 9.76294 0 10 0C10.2371 0 10.4376 0.166662 10.4831 0.398145L12.2972 9.49072C12.6253 11.1482 13.8012 12.5 15.3783 13.0464ZM13.1373 13.8912L15.6419 14.732C15.7535 14.7741 15.8333 14.8792 15.8333 15C15.8333 15.1209 15.7589 15.2313 15.6419 15.268L13.1373 16.1089C12.2174 16.4189 11.5314 17.1862 11.34 18.1269L10.2818 23.2875C10.2553 23.419 10.1383 23.5135 10 23.5135C9.86172 23.5135 9.74473 23.419 9.71813 23.2875L8.65998 18.1269C8.46851 17.1862 7.78255 16.4189 6.86269 16.1089L4.35807 15.268C4.24645 15.226 4.16667 15.1209 4.16667 15C4.16667 14.8792 4.24108 14.7688 4.35807 14.732L6.86269 13.8912C7.78255 13.5811 8.46851 12.8138 8.65998 11.8731L9.71813 6.71246C9.74473 6.58108 9.86172 6.48649 10 6.48649C10.1383 6.48649 10.2553 6.58108 10.2818 6.71246L11.34 11.8731C11.5314 12.8138 12.2174 13.5811 13.1373 13.8912Z"
                  fill="white"
                />{" "}
              </svg>
            </div>
            <div className="m-eco-text">
              <div className="eco-text-main">
                <span className="text-eco-sm text-eco-blue">
                  {item.UpdatesWord}
                </span>
                <span className="text-eco-h1 text-eco-white">
                  {item.UpdatesName}
                </span>
              </div>
            </div>
            <div className="max-w-eco eco-m-xs">
              <span className="max-w-eco eco-text-xs">
                {item.UpdatesDescription}
              </span>
            </div>
            <div className="max-w-eco eco-ma">
              <div className="max-w-eco eco-a-nobg">
                <a href={item.UpdatesButtonLink}>
                  <div className="max-w-eco eco-a">
                    {" "}
                    <span className="max-w-eco eco-a-text">
                      {item.UpdatesButtonText}
                    </span>{" "}
                  </div>
                </a>
                {this.props.isAdmin ? this.getUpdateButton(index) : null}
              </div>
            </div>
          </div>
          <img
            className="max-w-eco eco-img object-cover"
            src={variables.PHOTO_URL + item.UpdatesPhotoFileName}
          />
        </div>
        {this.props.isAdmin ? (
          <div className="centered">{this.getUpdateMenu(item)}</div>
        ) : null}
      </div>
    ));
  }

  getUpdateButton(index) {
    return (
      <button
        type="button"
        className="px-4 py-2 text-sm border-transparent transition inline-flex items-center justify-center border font-body font-bold rounded-lg shadow-sm focus:outline-none w-full sm:w-auto bg-[#C62222] text-[#FAFAFA] hover:bg-[#E35151]"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        onClick={() => {
          let elements = document.getElementsByClassName("container-updates");

          for (let i = 0; i < elements.length; i++) {
            if (index != i && elements[i].classList.contains("showmenu")) {
              elements[i].classList.toggle("showmenu");
            }
          }

          document
            .getElementsByClassName("container-updates")
            [index].classList.toggle("showmenu");
          this.resetState();
        }}
      >
        <span className="inline-flex items-center gap-1">Update</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-pencil-square"
          viewBox="0 0 16 16"
        >
          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
          <path
            fillRule="evenodd"
            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
          />
        </svg>
      </button>
    );
  }
  getUpdateMenu(item) {
    return (
      <div
        className="container-updates display-none"
        style={{
          maxWidth: "2200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "rgb(30, 40, 60)",
        }}
      >
        <div className="left-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Name:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.UpdatesName}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesName(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Description:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.UpdatesDescription}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesDescription(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Key Word:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.UpdatesWord}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesWord(event)}
            />
          </div>
        </div>
        <div className="right-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Link:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.UpdatesButtonLink}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesButtonLink(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Text:</p>
            <input
              type="text"
              id="inputText"
              defaultValue={item.UpdatesButtonText}
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesButtonText(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.updatesPhotoFileName !== "" &&
            this.state.updatesPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.updatesPhotoFileName}
              />
            ) : item.UpdatesPhotoFileName !== "" &&
              item.EventPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + item.EventPhotoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.imageUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc" }}>Password:</p>
            <input
              type="password"
              id="inputText"
              placeholder="  enter password"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changePassword(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <button
              type="button"
              className="click"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                const updates = {
                  updatesId: item.UpdatesId,
                  updatesName:
                    this.state.updatesName === ""
                      ? item.UpdatesName
                      : this.state.updatesName,
                  updatesDescription:
                    this.state.updatesDescription === ""
                      ? item.UpdatesDescription
                      : this.state.updatesDescription,
                  updatesButtonLink:
                    this.state.updatesButtonLink === ""
                      ? item.UpdatesButtonLink
                      : this.state.updatesButtonLink,
                  updatesButtonText:
                    this.state.updatesButtonText === ""
                      ? item.UpdatesButtonText
                      : this.state.updatesButtonText,
                  updatesWord:
                    this.state.updatesWord === ""
                      ? item.UpdatesWord
                      : this.state.updatesWord,
                  updatesPhotoFileName:
                    this.state.updatesPhotoFileName != undefined &&
                    this.state.updatesPhotoFileName != "" &&
                    this.state.updatesPhotoFileName != null
                      ? this.state.updatesPhotoFileName
                      : item.UpdatesPhotoFileName,
                  password: this.state.password,
                  wallet:
                    this.props.wallet == null ? "injwallet" : this.props.wallet,
                };
                this.updateClick(updates);
              }}
            >
              Update
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fillRule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }

  createMenu() {
    return (
      <div
        id="createMenu"
        className="container-events display-none"
        style={{
          maxWidth: "2200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "rgb(30, 40, 60)",
        }}
      >
        <div className="left-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Name:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesName(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Description:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesDescription(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Key Word:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesWord(event)}
            />
          </div>
        </div>
        <div className="right-column-e">
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Link:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesButtonLink(event)}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ color: "#ccc" }}>Button Text:</p>
            <input
              type="text"
              id="inputText"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changeUpdatesButtonText(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {this.state.updatesPhotoFileName !== "" &&
            this.state.updatesPhotoFileName !== undefined ? (
              <img
                width="250px"
                height="250px"
                src={variables.PHOTO_URL + this.state.updatesPhotoFileName}
              />
            ) : null}
            <input
              className="m-2"
              type="file"
              accept="image/*"
              onChange={(event) => this.imageUpload(event)}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#ccc" }}>Password:</p>
            <input
              type="password"
              id="inputText"
              placeholder="  enter password"
              style={{
                width: "100%",
                height: "50px",
                backgroundColor: "#444",
                color: "#ccc",
              }}
              onChange={(event) => this.changePassword(event)}
            />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <button
            type="button"
            className="click"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => {
              const updates = {
                updatesId: 0,
                updatesName: this.state.updatesName,
                updatesDescription: this.state.updatesDescription,
                updatesButtonLink: this.state.updatesButtonLink,
                updatesButtonText: this.state.updatesButtonText,
                updatesWord: this.state.updatesWord,
                updatesPhotoFileName: this.state.updatesPhotoFileName,
                password: this.state.password,
                wallet:
                  this.props.wallet == null ? "injwallet" : this.props.wallet,
              };
              this.createClick(updates);
            }}
          >
            Create
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
  getCreateButtonMenu() {
    return (
      <div>
        {this.createMenu()}
        <button
          type="button"
          className="click"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => {
            const doc = document.getElementById("createMenu");
            if (doc) {
              doc.classList.toggle("showmenu");
            }
            this.resetState();
          }}
        >
          Add Updates
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-square"
            viewBox="0 0 16 16"
          >
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
            <path
              fillRule="evenodd"
              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
            />
          </svg>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h2 className="font-semibold w-full text-3xl font-semibold mt-5 mb-5 adaptive-spotlight">
          Discover More
        </h2>
        <div
          className="flex justify-center items-center adaptive-spotlight"
          style={{ backgroundColor: "#0D1420" }}
        >
          <div
            className="grid mx-10 auto-rows-[40rem] grid-cols-1 xl:gap-12 xl:grid-cols-3"
            style={{ gridGap: "20px" }}
          >
            {this.getUpdates()}
          </div>
        </div>
        {this.props.isAdmin ? this.getCreateButtonMenu() : null}
      </div>
    );
  }
}
